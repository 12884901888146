import React from 'react'

const ResponsibleGaming = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> Responsible Gaming Policy </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Responsible Gaming   </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="common_content_box">
                <div className="container">
                 

                <div id="privacypolicy" class=" container">
                        <p class="">Fantasy Score 11 Pvt. Ltd. (the <b class="">“Company”</b>), the operator of this online gaming website, <a href="/" class="">www.skillwinz.com</a>(<b class="">“Website”</b>, inclusive of
                            the mobile application), strives to endorse responsible gaming, and improve the prevention and avoidance of excessive gaming. This Responsible Gaming Policy describes the Company’s commitments, and your responsibility to promote responsible gaming
                            practices and minimize the negative effects of excessive gaming. Games may involve an element of financial risk and may be addictive. Please play responsibly and at your own risk.</p>
                        <p class="">If you choose to play on our Website, there are some general guidelines that can help make your playing experience safer, and avoid risk that you may indulge in excessive gaming:</p>
                        <ul class="">
                            <li class="">Add money to the Skillwinz Wallet only with money that you can afford to spend.</li>
                            <li class="">Never add money to the Skillwinz Wallet with money that you need for important or essential things such as food, rent, bills, or tuition.</li>
                            <li class="">Pre-plan your purchases and the time you will spend playing on the Website and stick to that limit, regardless of your performance.</li>
                            <li class="">Don't play when you are upset, tired, or depressed. It is difficult to make good decisions when you are feeling down.</li>
                            <li class="">Do not put off personal and other important tasks in order to play. Complete all your important tasks so that you can play with a free and calm mind.</li>
                            <li class="">Balance your gameplay duration with other activities. Find other forms of entertainment so playing does not become too big a part of your life.</li>
                            <li class="">Do not borrow money in order to make purchases on the Website.</li>
                            <li class="">Avoid using all of the monies in the Skillwinz Wallet in a single game or session.</li>
                            <li class="">Take time outs and breaks regularly.</li>
                            <li class="">Only participate in games that are commensurate with your competence level and financial capabilities. Do not get tempted to participate in high stakes tables despite winning consistently.</li>
                        </ul>
                        <h4 class="mt-3">Age Exclusion</h4>
                        <p class="">Any underage player who has provided dishonest or inaccurate information regarding their true age may have all winnings forfeited and could face criminal prosecution.</p>
                        <ul class="">
                            <li class="">Every person signing up for a new account must check a box that indicates that they are at least 18 years of age. This notifies everybody that we don’t accept players under 18.</li>
                            <li class="">When a player creates an account with us, we allow them an option to provide their name, address, and birth date for maintaining their user profile on the Website.</li>
                            <li class="">We never target underage players with our marketing and advertising. It is neither good business nor consistent with our personal and corporate values to attract underage players.</li>
                            <li class="">We are a member of the All-India Gaming Federation (<b class="">"AIGF"</b>), with the purpose of self-regulating all Online Games of Skill within the territory of the Republic of India. AIGF’s Skill Games Charter may be accessed
                                </li>
                        </ul>
                        <h4 class="mt-3">Company Responsibilities</h4>
                        <p class="">We may notify users in case their accrued spending exceeds a certain limit. Users may be notified when their game sessions are long to take an optional break. And if users’ session exceeds a certain time, the Company may mandate the user to take a
                            non-negotiable break. The user, in this case, may not use the Website. </p>
                        <h4 class="">How do you know if you may be indulging in excessive gaming?</h4>
                        <p class="">Ask yourself the following questions:</p>
                        <ul class="">
                            <li class="">Have you often played games longer than you had planned?</li>
                            <li class="">Have you often played games until your last rupee was gone?</li>
                            <li class="">Have you used your income or savings to play games while letting bills go unpaid?</li>
                            <li class="">Have you made repeated, unsuccessful attempts to stop playing games?</li>
                            <li class="">Have you considered breaking the law to finance your gaming activities?</li>
                            <li class="">Have you borrowed money to finance your gaming activities?</li>
                            <li class="">Have you felt depressed because of money you may have lost playing games?</li>
                            <li class="">Have you been remorseful after you have spent time playing games?</li>
                            <li class="">Have you played games to get money to meet your financial obligations?</li>
                        </ul>
                        <p class=""><b class="">If you or someone you know answers yes to any of the above questions, please consider seeking professional help or advice.</b></p>
                    </div>



                </div>
            </section>
            {/* <!-- Copyright Area Start --> */}
            <div className="copyright-area  ptb-70 pt-0 pb-4">
                <div className="container">
                    {/* <!-- Contact address left --> */}
                    <div className="conct-border row align-items-center">
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" /> </a>
                        </div>
                        <div className="col-md-7">
                            <div className="single-address">
                                <a href="/fairplay"  >Skillwinz Fairplay</a>
                                <a href="/termsconditions">Terms & Conditions</a>
                                <a href="/privacypolicy">Privacy Policy</a>
                                <a href="/legality"> Legality </a>
                                <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                <a href="/anti-money-laundering-policy">Anti-Money Laundering  </a>
                                <a href="/refund-policy"> Refund Policy  </a>
                                <a href="/contact"> Contact us    </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Contact address left --> */}
                    {/* <!-- Copyright right --> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area text-center">
                                {/* <!-- Copyright social --> */}
                                {/* <!-- <div className="contact-social text-center ptb-50 pb-35">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-vimeo"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-google-plus"></i></a>
                                        </li>
                                    </ul>
                                </div> --> */}
                                {/* <!-- Copyright social --> */}
                                <div className="copyright-text">
                                    <p>Copyright &copy;  <a href="#"> Skill Winz </a> All Rights Reserved.  Design by <a href="https://appinop.com" target="_blank"  >Appinop Technologies</a><br/>Skillwinz is a part of  Fantasy Score 11 Pvt. Ltd.</p>
                                
                                    <p>
                                        <b>DISCLAIMER</b><br/>
                                        This game may be habit-forming or financially risky. Play responsibly. Skillwinz is the biggest social gaming app in India by number of games, languages and exciting formats on the platform. Skillwinz is only available for people who are above 18 years of age. Skillwinz is available only in those Indian states where skill gaming is allowed by regulations.Fantasy Score 11 Pvt. Ltd. is the sole owner of and reserves the right to “Skillwinz” trademark, logos, assets, content, information, etc. used in the website except the third party content. Fantasy Score 11 Pvt. Ltd. does not acknowledge the accuracy or reliability of third party content.
                                    </p>
                                </div>
                                {/* <!-- Copyright text --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Copyright right --> */}
                </div>
            </div >
            {/* <!-- Copyright Area End --> */}
        </>
    )
}

export default ResponsibleGaming
