import React from 'react';
import Routing from "./Routing";

function App() {
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
