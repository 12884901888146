import React from 'react'

const TermsConditions = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> Terms And Conditions </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Terms And Conditions</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="common_content_box">
                <div className="container">
                <div class="">
                     
                    <p class="">&nbsp; &nbsp; PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE PLAYING ANY MOBILE GAMES OR/AND USING THE SKILLWINZ PLATFORM.</p>
                    <p class="">&nbsp; &nbsp; If You do not agree with any part of these Terms of Use or/and Privacy Policy, kindly refrain from playing any Mobile Games or otherwise using Our services on the Platform.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; We have tried Our best to keep these Terms of Use as simple and short as possible. Nevertheless, We realise that this is a rather long document despite all our efforts. For Your convenience, We have summarised some of the key clauses and linked them to the relevant section. Bear in mind that the summary highlights just the key aspect of the relevant section and not the entire section itself. So please refer to the section itself to get the full picture.</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; You may only use Our web portal https://skillwinz.com/privacypolicy, partner websites, mobile applications and other portals (collectively, the “Platform”) if You agree to abide by the terms and conditions as laid down in this Agreement. To better understand the scope of these Terms of Use, how they may be updated and the meaning of the various words used in these Terms of Use please refer to the Introduction section of these Terms of Use. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Only people of sound mind who are above 18 years of age may use this Platform. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; This Platform may not be used by residents of Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, Tamil Nadu and Telangana. Further, there may be certain games that may be restricted in some additional states. To understand more about who may use this platform please refer to Our Eligibility section &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; You may register on the Platform and add cash to Your account by following the registration procedure laid down in the section on Nature and use of the Platform. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Please note that Your User Details will be accessible to other users on the Platform. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; The rules governing Your participation in the Contests available on Our Platform are available at Our section on Participation. Further, each Mobile Game may be subject to its own Game Rules and Code of Conduct. To understand Mobile Games better please refer to Our section on Mobile Games. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Please note that We have the sole discretion to determine the terms applicable on any Contest including the Awards to be given in any Contest. Further, the Designated Amount paid for any contest is non-refundable and may only be refunded under the circumstances stated in Our Return, Refund and Cancellation policy. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Winnings may be withdrawn by You from Your account, subject to the completion of KYC verification and account validation and will be subject to TDS deductions. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Only skill-based games are available on Our Platform. To understand more about the nature of games offered on Our Platform please refer to Games of skill section. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; We further request that You follow the precautions laid down in Our Safety section while playing Mobile Games on Our Platform. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; While using the various communication channels provided on Our Platform You must abide by rules stated in Our Community rules and code of conduct section.&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; You may not use the Platform in ways stated in Our Restrictions section. You will indemnify Us in the event We suffer any losses on account of Your use of Our Platform or Your failure to abide by this Agreement. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Circumstances under which Your access to the Platform or this Agreement may be terminated are stated in the Termination section. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Your use of this Platform is completely at Your own risk. We disclaim all warranties in relation to the Platform. To understand more please refer to Our Disclaimer section. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Any dispute between You and Us will be resolved by way of Arbitration which will be conducted in Jaipur by a sole arbitrator appointed by Us. For further details refer to Our Governing law and dispute resolution section. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; For information about Our Referral program, please click here. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; For rules governing SKILLWINZ SuperTeam, please click here. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; If You have any complaints or grievances over any of the Services offered on the Platform, please contact our Grievance Officer.&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; To know about our Anti-Money Laundering Policy, please click here &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Introduction</strong></i></p>
                    <p class="">&nbsp; &nbsp; 1.1. Our Company is an Indian company incorporated under the Companies Act, 2013. SKILLWINZ operates a mobile gaming portal in India, which offers various skill and learning based digital games developed by Third-Party Game Developers and other promotional and interactive features (collectively “<strong class="">Services</strong>”) through its web portal https://skillwinz.com/, partner websites, mobile applications and other portals (collectively, the “<strong class="">Platform</strong>”).</p>
                    <p class="">&nbsp; &nbsp; 1.2 . Capitalized terms used herein but not defined shall have the meanings ascribed to them under section 1.9 (<i class="">Definitions and Interpretation</i>) of this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 1.3 . This Agreement shall apply to all Persons who visit the Platform, or create a player Account including any contributors of content over the Platform, and/or any third party interacting with SKILLWINZ, directly or indirectly in official capacity or having/intending to have business or commercial relationships with SKILLWINZ, whatsoever (the “<strong class="">Users</strong>”, “<strong class="">You</strong>” or “<strong class="">Your</strong>”) as applicable..</p>
                    <p class="">&nbsp; &nbsp; 1.4 . By registering on, browsing, downloading, using or accessing any part of the Platform or utilizing the Services, the Users agree to be bound by this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 1.5 . If at any time the User does not accept or agree to any terms of this Agreement, on a continuing basis in relation to any of the Services offered on the Platform, they should not access the Platform.</p>
                    <p class="">&nbsp; &nbsp; 1.6 . SKILLWINZ reserves the right to periodically review, update, change or replace any part of these Terms of Use or other terms of the Agreement as it may consider appropriate at its sole and absolute discretion</p>
                    <p class="">&nbsp; &nbsp; 1.7 . Any changes to these Terms of Use will be posted on this page wherever appropriate. The Users must visit this page periodically for updates on the Terms of Use applicable to their use of the Platform.</p>
                    <p class="">&nbsp; &nbsp; 1.8 . Any Users’ continued usage of the Platform after any such update, change, replacement or modification in the Agreement constitutes their valid consent to such changes.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">1.9 .&nbsp;</strong><i class=""><strong class="">Definitions&nbsp;</strong></i></p>
                    <p class="">&nbsp; &nbsp; 1.9.1 .&nbsp;<i class=""><strong class="">“Agreement”</strong></i> shall mean these Terms of Use, the Privacy Policy and any other rules or policies available on the Platform including all other additional or modified terms and conditions and policies in relation to the Platform or any current or future services that may be offered by SKILLWINZ. This Agreement is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediary Guidelines) Rules, 2011. This Agreement is an electronic record generated by a computer system and does not require any physical or digital signatures.</p>
                    <p class="">&nbsp; &nbsp; 1.9.2 .&nbsp;<i class=""><strong class="">“Applicable Law</strong></i>” means all laws, statutes, common law, regulations, ordinances, codes, rules, guidelines, orders, permits, tariffs and approvals, including those relating to health and safety, of any governmental authority or self-regulatory guideline that may apply to the Users or is related to the subject matter of this Agreement as applicable in the territory of India.</p>
                    <p class="">&nbsp; &nbsp; 1.9.3 .&nbsp;<i class=""><strong class="">“Contest”&nbsp;</strong></i>is a skill based competition offered by SKILLWINZ on the Platform in which Users compete either in a tournament or 1v1 battle by playing the Mobile Games on the Platform. Contests can be either ‘paid’ or ‘free to play’. Paid Contests require the Users to pay a Designated Amount for participation, whereas free to play Contests are open to all Users without requirement of any monetary payment.</p>
                    <p class="">&nbsp; &nbsp; 1.9.4 .&nbsp;<i class=""><strong class="">“Balance”</strong></i> is the credit (either in the form of cash or SKILLWINZ Tokens) available in an Account and includes amount added by a User through Deposits or/and earned through Winnings by participating in Contests on the Platform.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 1.9.5 .&nbsp;<i class=""><strong class="">“Deposit”</strong></i> is the amount added by the User to his/her Account through his/her preferred payment mode (e-wallet, debit/credit cards, UPI/BHIM, net banking). The Deposit conversion ratio is INR. 1/- (Rupees One Only) = INR 1 (one).</p>
                    <p class="">&nbsp; &nbsp; 1.9.6 .&nbsp;<i class=""><strong class="">“Designated Amount”</strong></i> refers to the amount which Users pay either in the form of cash Balance available in their Account to participate in a Contest on the Platform as their respective contribution to the Prize Money Pool.</p>
                    <p class="">&nbsp; &nbsp; 1.9.7 .&nbsp;<i class=""><strong class="">“Mobile Games”&nbsp;</strong></i>refers to the digital games of skill including  games available on the Platform developed by SKILLWINZ or Third-Party Game Developers.</p>
                    <p class="">&nbsp; &nbsp; 1.9.8 .&nbsp;<i class=""><strong class="">“Person”&nbsp;</strong></i>shall mean any natural person, limited or unlimited liability company, corporation, partnership (whether limited or unlimited), proprietorship, Hindu undivided family, trust, union, association, government or any agency or political subdivision thereof or any other entity that may be treated as a person under the Applicable Law.</p>
                    <p class="">&nbsp; &nbsp; 1.9.9 .&nbsp;<i class=""><strong class="">“Prize Money Pool”</strong></i> is the actual or projected amount that is to be distributed amongst the winner(s) of the Contest and is declared at the start of the Contest.</p>
                    <p class="">&nbsp; &nbsp; 1.9.10.&nbsp;<strong class="">“</strong><i class=""><strong class="">Sponsored Content”</strong></i> means content distinct from other regular editorial content provided by SKILLWINZ, and displayed on the Platform in the form of audio, video, text and/or image media which supports a third party’s brand message, promotional offers, discounts and/or views that are readily identifiable to belong to a third-party upfront through distinct and distinguishable font, colour, display schemes and/or usage of disclaiming words (such as “<strong class="">Ad</strong>”, “<strong class="">Powered by</strong>”, “<strong class="">Sponsored by</strong>”, “<strong class="">With</strong>”, “<strong class="">Advertorial</strong>” and such other phrase of similar meaning).</p>
                    <p class="">&nbsp; &nbsp; 1.9.11 .&nbsp;<i class=""><strong class="">“Sponsored Content Partner”</strong>&nbsp;</i>means a User engaged with Us for the display of Sponsored Content on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 1.9.12 .<i class=""><strong class=""> “Third Party Game Developer”&nbsp;</strong></i>refers to any Person (natural or juristic) whose Mobile Game is available on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 1.9.13 .&nbsp;<i class=""><strong class="">"SKILLWINZ  Token”&nbsp;</strong></i>is an award in the form of SKILLWINZ tokens that may be given to any User at SKILLWINZ’s sole discretion for participating on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 1.9.14&nbsp;<i class=""><strong class="">“Winnings”</strong></i> are awards (either in the form of cash, SKILLWINZ Tokens or promotional gifts) given to one or more User(s) as per pre-declared format as a distribution of the Prize Money Pool for winning and/or ranking as a top-performer in a particular Contest on the Platform.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">1.10 . Interpretation</strong></i></p>
                    <p class="">&nbsp; &nbsp; 1.10.1 . Any reference to the singular includes a reference to the plural and vice versa, and any reference to one gender includes a reference to other gender(s), unless explicitly provided for.</p>
                    <p class="">&nbsp; &nbsp; 1.10.2 . Headings and captions are used for convenience only and not for interpretation of the Agreement.</p>
                    <p class="">&nbsp; &nbsp; 1.10.3 . Any reference to a natural Person shall, include his/her heirs, executors and permitted assignees and any reference to a juristic Person shall, include its affiliates, successors and permitted assignees, unless repugnant to the context.</p>
                    <h4 class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Eligibility&nbsp;</strong></i></h4>
                    <p class="">&nbsp; &nbsp; 2.1 . Any browsing, registering, downloading, using or accessing by any Person of any part of the Platform or utilisation of any of the services offered by SKILLWINZ will be deemed to constitute a legal contract between the User and SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; 2.2 . A User may use the Platform if (a) it is a natural Person, as per any Applicable Law, regulation or generally accepted practices or guidelines applicable in the country of which User is a citizen, in which User is a resident or from where User uses the Platform; or (b) it is a juristic Person, as per any Applicable Law, regulation or generally accepted industry practices and guidelines applicable in the country where User is registered, where User maintains its principal office or from where User uses the Platform</p>
                    <p class="">&nbsp; &nbsp; 2.3 . Any User who is “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. is not eligible to enter into this Agreement with SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; 2.4 . The Platform should not be used, installed, or played by any Person under the age of 18 years.</p>
                    <p class="">&nbsp; &nbsp; 2.5 . No Person, whether an SKILLWINZ employee or not, who is involved in the development of Mobile Games or/and algorithms on the Platform can participate in any Mobile Games on the Platform, where such participation could alter, modify or unduly influence his/her performance on the Mobile Game, without express written permission of SKILLWINZ and subject to such terms and conditions as SKILLWINZ may seek to impose on such Person.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Special Terms for Tamil Nadu:</strong></p>
                    <ol class="">
                        <li class="">&nbsp; &nbsp; Users from Tamil Nadu are restricted from participating in any Contest relating to any game involving use of cards or dice. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; The restriction from use further applies to any games where the gameplay relies dominantly on random number generation. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Users will be be able to participate in Contests of all other games, each of which will be specified on the Platform. &nbsp; &nbsp;</li>
                    </ol>
                    <p class="">&nbsp; &nbsp; 2.7 . Only those Users who have successfully registered on the Platform in the manner prescribed in section 3 (<i class="">Nature and Use of the Platform</i>), and who utilize the services of the Platform in accordance with the Agreement including these Terms of Use, shall be eligible to use the Platform.</p>
                    <p class="">&nbsp; &nbsp; 2.8 . Certain services being provided on the Platform may be subject to additional conditions or rules applicable to a particular Mobile Game or Contest in that respect (“<strong class="">Game Terms</strong>”). Such Game Terms are deemed to be incorporated herein by reference. Users must agree to these Game Terms before playing any such Mobile Game or Contest on the Platform. To the extent that these Terms of Use are inconsistent with Game Terms, the Game Terms shall prevail.</p>
                    <p class="">&nbsp; &nbsp; 2.9 . SKILLWINZ reserves the right to terminate any User’s Account and/or deny access to the Platform if it is brought to SKILLWINZ’s notice that a particular User has violated the Agreement including any of these Terms of Use.</p>
                    <h4 class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Nature and use of the Platform</strong></i></h4>
                    <p class="">&nbsp; &nbsp; 3.1 . SKILLWINZ provides the Platform to You on which You can play Mobile Games either offered by SKILLWINZ or independent Third Party Game Developers. The Platform is designed to provide You an in-app browsing experience through an embedded browser. These Mobile Games run online over the Platform and do not have to be separately downloaded or installed.</p>
                    <p class="">&nbsp; &nbsp; 3.2 .<i class=""><strong class="">Registration&nbsp;</strong></i><strong class="">&nbsp;You may register by approving and agreeing to this Agreement and providing Your registered mobile phone number on the Platform. You will be verified by SKILLWINZ using a one-time password (“OTP”) system. Upon successfully verifying the OTP, a deposit cash and winnings cash account will be created for You (“Account”).</strong></p>
                    <p class="">&nbsp; &nbsp; 3.3 . By registering for an Account, You represent, warrant and agree that:</p>
                    <p class="">&nbsp; &nbsp; a) You fulfil the eligibility criteria as detailed in section 2 (<i class="">Eligibility</i>);&nbsp;</p>
                    <p class="">&nbsp; &nbsp; b) You are using Your actual identity and the mobile phone number you have provided is registered in Your own name;</p>
                    <p class="">&nbsp; &nbsp; c) You will provide only true, accurate, current, and complete information about Yourself, including optional information such as Your profile photograph, cover photograph, user-id, biograph, audio status etc., on the Platform (<strong class="">“User Details”</strong>);&nbsp;</p>
                    <p class="">&nbsp; &nbsp; d) You will maintain and promptly update the User Details and other information that You provide on the Platform to keep it true, accurate, current, and complete.</p>
                    <p class="">&nbsp; &nbsp; 3.4 .The Users acknowledge that their User Details will be accessible to other User on the Platform and shall be available for SKILLWINZ to create promotional and interactive content over the Platform.</p>
                    <p class="">&nbsp; &nbsp; 3.5 . Your Account is for Your personal use only. You shall not impersonate someone else. By creating an Account after providing a mobile phone number and verifying it using an OTP, You represent, warrant, and agree that You are authorized to use the Platform and will abide by the Agreement.&nbsp;</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">3.6 Privacy</strong></i></p>
                    <p class="">&nbsp; &nbsp; a) Our privacy policy available at http://skillwinz.com/privacypolicy/ (“<strong class="">Privacy Policy</strong>”) explains how We treat Your personal data and protect Your privacy when You use the Platform and our Services. By using the Platform, You agree that We can use Your data and other information according to the Privacy Policy.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; b) You are responsible for maintaining the confidentiality of passwords associated with any device You use to access the Platform. Accordingly, You are solely responsible for all activities that occur with Your device. If You become aware of any unauthorised use of Your device, You are required to notify the relevant authorities as soon as possible.</p>
                    <p class="">&nbsp; &nbsp; c) By visiting the Platform and creating an account on the Platform, You grant SKILLWINZ and its group companies your irrevocable and informed consent to use your profile name, profile picture, and Winnings in a Contest and/ or Winnings in total on the Platform (“<strong class="">Your Profile Information</strong>“) for advertisements, promotions, offers, and any other sponsored content that SKILLWINZ and its group companies may display on the Platform or any other marketing channels, including its digital channels, television, print and publication, without requiring any further consent from You and without being required to pay any compensation to You.</p>
                    <p class="">&nbsp; &nbsp; d) You further grant to SKILLWINZ an exclusive, transferable, sub-licensable, royalty-free and worldwide licence to host, use, distribute, modify, run, copy, publicly perform or display, translate and create derivative works from Your Profile Information.</p>
                    <p class="">&nbsp; &nbsp; e) You understand, represent and accept that Your Profile Information or any related materials will not violate the rights of any third party rights or give rise to any claim that another party’s rights have been or will be violated as a result of SKILLWINZ’s use or publication of Your Profile Information. You understand and agree that You will be liable to SKILLWINZ for any damage or cost (including reasonable attorney fees) it may suffer arising out of its use of Your Profile Information. You also understand that you will not be entitled to receive any royalties for the use of your Profile Information by or through SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; f) You understand and grant to SKILLWINZ, its subsidiaries, affiliates, successors and those acting with its authority, with respect to Your Profile Information all copyrights and derivative rights in Your Profile Information and a non-exclusive, perpetual right to use, publish, re-publish or reproduce Your Profile Information by any means SKILLWINZ sees fit for the purposes stated above.</p>
                    <p class="">&nbsp; &nbsp; g) By using the Platform, you irrevocably waive any claim against SKILLWINZ relating to the use of Your Profile Information and promise not to bring any such claim or action in the future. You also waive any right to inspect, modify, approve or disapprove the content, layout, representation, presentation or other aspect of Your Profile Information as recorded by SKILLWINZ during your use of the Platform.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">3.7 Deposits</strong></i></p>
                    <p class="">&nbsp; &nbsp; a) In order to participate in certain Contests, the Users may be required to pay a Designated Amount in the form of cash. Users may add cash to their Account by using any of the payment modes available on the Platform.</p>
                    <p class="">&nbsp; &nbsp; b) The Deposits may be used to participate in Contests in the manner provided under section 4 (<i class="">Participation in Contests</i>).</p>
                    <p class="">&nbsp; &nbsp; 3.8 .Each User is solely responsible for all activities that occur under their Account, including the use of any of the Services on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 3.9 . SKILLWINZ grants such Users a non-transferrable, limited, non-exclusive and revocable privilege to register an Account on and use the Platform.</p>
                    <p class="">&nbsp; &nbsp; 3.10 . If SKILLWINZ identifies that the same User Details are being used under multiple accounts, then SKILLWINZ at its sole discretion will terminate/deactivate the Account without the Users’ consent and any Deposit in the Account will not be refunded.</p>
                    <p class="">&nbsp; &nbsp; 3.11 . SKILLWINZ, at its sole and absolute discretion and without any notice to the Users, reserves the right to restrict, suspend or terminate any Account or access to the Platform temporarily or permanently to any User.</p>
                    <p class="">&nbsp; &nbsp; 3.12 . SKILLWINZ may, at its sole and absolute discretion restrict, change, suspend, or terminate any User’s access to all or any part of the Platform, including but not limited to:</p>
                    <p class="">&nbsp; &nbsp; a) reject, move, or remove any material that may be submitted by the User;</p>
                    <p class="">&nbsp; &nbsp; b) move or remove any content that is available on the Platform;</p>
                    <p class="">&nbsp; &nbsp; c) deactivate or delete an Account and all related information and files on their Account;</p>
                    <p class="">&nbsp; &nbsp; d) establish general practices and limits concerning use of the Platform and the services; and</p>
                    <p class="">&nbsp; &nbsp; e) assign its rights and liabilities with respect to all Accounts hereunder, to any entity.</p>
                    <p class="">&nbsp; &nbsp; 3.13 . Notwithstanding anything to the contrary herein, the Users acknowledge and agree that they shall have no ownership or other interest in their Account, and further acknowledge and agree that all rights in and to their Account are and shall forever be owned by and inure to the benefit of SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; 3.14 . The Users also accepts to receive news, updates, offers/campaign related SMS, to the mobile phone number provided by the User, except when prohibited under Applicable Laws.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">3.15. Platform Fee Information</strong></i></p>
                    <p class="">&nbsp; &nbsp; a) SKILLWINZ charges a Platform Fee from the User(s), for making available its e-platform where various games of skill &amp; other content are available and providing the service to the Users to participate in those games of skill or consume the content as offered.</p>
                    <p class="">&nbsp; &nbsp; b) The Platform Fee for all the products/games is a a fixed rake % of the Designated Amount and is between 0% and 33% and under extreme exceptional cases may go beyond this range.</p>
                    <p class="">&nbsp; &nbsp; c) SKILLWINZ, in accordance with the Terms &amp; Conditions of the Bonus Program, may give a Discount in the Platform Fee and thus the Designated Amount shall be accordingly recovered from the User’s Cash Balances.</p>
                    <p class="">&nbsp; &nbsp; d) This can be easily deduced from the Designated Amount and the total prize offered.</p>
                    <p class="">&nbsp; &nbsp; Some examples are as under :-</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Platform Fee Examples</strong></p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Tournaments&nbsp;</strong></p>
                    <p class="">&nbsp; &nbsp; For each user who joins a cash tournament, SKILLWINZ Platform Fee is calculated as (1- Total Cash prize offered/(open slots x Designated Amount)) * Designated Amount</p>
                    <p class="">&nbsp; &nbsp; For example, in a 5 player tournament with Designated Amount of रु100, and the total cash prizes on offer is रु450.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; The SKILLWINZ Platform Fee collected out of each user who is registering with रु 100 will be equal to (1 – रु450/(रु100 x 5)) * रु100 = रु10</p>
                    <p class="">&nbsp; &nbsp; The same calculation applies to tournaments hosted by Users, Pooled tournaments or Non-Guaranteed tournaments.</p>
                    <p class="">&nbsp; &nbsp; Pooled Tournaments =&nbsp; Tournaments where the Total winnings on offer increases with the number of slots filled in the tournament. However, the Platform Fee on each registration remains constant for any number of slots filled.</p>
                    <p class="">&nbsp; &nbsp; Non-Guaranteed Tournaments = Tournaments which get cancelled and all Designated Amount paid shall be refunded if the minimum number of users required did not join the tournament</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Battles</strong></p>
                    <p class="">&nbsp; &nbsp; For each successfully completed 2 player cash battle, SKILLWINZ Platform Fee is calculated as (1- Total Cash prize offered/(2 x Designated Amount)) * Designated Amount</p>
                    <p class="">&nbsp; &nbsp; For example, in a 2 player battle with Designated Amount of रु 100, where the winner(s) gets रु 180.</p>
                    <p class="">&nbsp; &nbsp; The SKILLWINZ Platform Fee collected out of each user who is registering with रु 100 will be equal to (1 – रु180/(रु100 x 2)) * रु100 = रु10</p>
                    <p class="">&nbsp; &nbsp; In the case of multiplayer battles where multiple players could win cash prizes, Total cash prize offered varies with the number of players participated in the battle</p>
                    <p class="">&nbsp; &nbsp; SKILLWINZ Platform Fee is calculated as (1- Total Cash prize offered per the number of players who participated/(number of players who participated x Designated Amount)) * Designated Amount</p>
                    <p class="">&nbsp; &nbsp; For example, let’s take the case of a 5 player battle with रु 100 Designated Amount. When 3 players participate, if the total prize offered to all the winners in the battle is रु 270.</p>
                    <p class="">&nbsp; &nbsp; Then SKILLWINZ Platform Fee collected out of each who is participating with रु 100 will be equal to (1 – रु270/(रु100 x 3)) * रु100 = रु10</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Battle Arenas</strong></p>
                    <p class="">&nbsp; &nbsp; Battle Arenas are a kind of battles where users pay a single Designated Amount to register. Players then compete with each other in a battle format.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; By the end of the duration of the Battle Arena, players are ranked on a leaderboard based on the number of wins he/she had out of all the battles he/she played.</p>
                    <p class="">&nbsp; &nbsp; Cash Prizes are distributed to the winners based on their rank.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; SKILLWINZ Platform Fee for such registrations is a preset percentage of the Designated Amount.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; SKILLWINZ Platform Fee is subject to change as per SKILLWINZ policy without prior notice.&nbsp;</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class=""> Contests&nbsp;</strong></p>
                    <p class="">&nbsp; &nbsp; For each user who joins a cash contest, SKILLWINZ Platform Fee is calculated as (1- Total Cash prize offered/(open slots x Designated Amount)) * Designated Amount</p>
                    <p class="">&nbsp; &nbsp; If a contest allows a user to register multiple teams, then each team registered by the user is counted as a slot filled in the contest.</p>
                    <p class="">&nbsp; &nbsp; For example, in a 5 player contest with Designated Amount of रु100, and the total cash prizes on offer is रु450.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; The SKILLWINZ Platform Fee collected out of each user for each team he/she is registering with रु 100 will be equal to (1 – रु450/(रु100 x 5)) * रु100 = रु10</p>
                    <p class="">&nbsp; &nbsp; The same calculation applies to contests hosted by Users, Pooled contests or Non-Guaranteed contests.</p>
                    <p class="">&nbsp; &nbsp; Pooled contests =&nbsp; contests where the Total winnings on offer increases with the number of slots filled in the tournament. However, the Platform Fee on each team registration remains constant for any number of slots filled.</p>
                    <p class="">&nbsp; &nbsp; Non-Guaranteed contests = contests which get cancelled and all Designated Amounts paid shall be refunded if the minimum number of users required did not join the tournament</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Audio Shows</strong></p>
                    <p class="">&nbsp; &nbsp; All cash paid to other SKILLWINZ players for hosting or co-hosting Audio Shows are subject to a SKILLWINZ Platform Fee of 12% of the Cash paid.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; For example if an SKILLWINZ players tips a host or a co-host of an audio show for रु 50. Then SKILLWINZ Platform Fee described as Audio Transfer Charges of रु6 is deducted from the Host/Co-Hos</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Points rummy</strong></p>
                    <p class="">&nbsp; &nbsp; SKILLWINZ Platform Fee for each table is equal to sum of Rake % * Points Given * point value of the table by each of the players barring the winner.</p>
                    <p class="">&nbsp; &nbsp; Rake % varies across points rummy tables. It can be found when tapped on the info button at the top right of each table.</p>
                    <p class="">&nbsp; &nbsp; For example, in a 0.1 point value lobby with a rake % of 12%, in a round if 5 players were playing and one player won. Say each of the 4 players who lost gave 25 points each.</p>
                    <p class="">&nbsp; &nbsp; Then the total Platform Fee = 12% * 25*4 * 0.1 = RS.1.2</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Pool Rummy</strong></p>
                    <p class="">&nbsp; &nbsp; For example, in a pool rummy game of RS. 100 entry and 5 people have played it. Let’s say the Rake % for this table is 12%. Then in the current example, SKILLWINZ Platform Fee will be calculated as 12%* 100*5 = Rs.60</p>
                    <p class="">&nbsp; &nbsp; The Rake % varies from table to table and from time to time. You can check the value by taking on the info icon at the top right of each table.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">3.16. Processing Fee</strong></p>
                    <p class="">&nbsp; &nbsp; a) SKILLWINZ charges a ‘Processing Fee’ from the User(s) for facilitating withdrawal of Winnings through various modes including but not limited to Bank Account and UPI. &nbsp; &nbsp;&nbsp;&nbsp;
                        <br class=""/>&nbsp; &nbsp; b) The Processing Fee shall be levied as per the following schedule:</p>
                    <p class="">&nbsp; &nbsp; i.&nbsp; <i class="">First three withdrawals</i>: No Processing Fee shall be levied for the first three withdrawals done by a User on the Platform, i.e. Processing Fee will be levied from the fourth withdrawal onwards;</p>
                    <p class="">&nbsp; &nbsp; ii.&nbsp; <i class="">Withdrawal amount between INR 0</i>/-<i class=""> – INR 500</i>/-: A Processing Fee of 4% on the withdrawal amount (fourth withdrawal onwards) shall be levied by SKILLWINZ on each withdrawal done by a User;</p>
                    <p class="">&nbsp; &nbsp; iii.<i class=""> Withdrawal amount over INR 500</i>: No Processing Fee shall be levied on any withdrawal where the withdrawal amount is over INR 500/-.</p>
                    <p class="">&nbsp; &nbsp; c) In accordance with the Terms &amp; Conditions, SKILLWINZ reserves the right to revise the Processing Fee and its levy schedule at its sole discretion.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Participation in contest</strong></i></p>
                    <p class="">&nbsp; &nbsp; 4.1 . The Users may use the Platform to participate in a variety of Contests available on the Platform.<strong class="">&nbsp;</strong></p>
                    <p class="">&nbsp; &nbsp; 4.2 . In order to participate in a Contest, each User may be required to pay a Designated Amount to the Platform which is deducted from the Balance available in the Account of the User. The payment of the Designated Amount that Users make to participate in the Contest(s) is inclusive of the Platform fee for access to the SKILLWINZ Services charged by Us and User’s contribution towards Prize Money Pool.</p>
                    <p class="">&nbsp; &nbsp; 4.3 . Each Contest has a pre-determined limit on the number of Users that can participate and a specified time before which entries must be made. A Contest is deemed to be a valid Contest on the Platform, only if the requisite number of Users register for the Contest before the prescribed entry time.&nbsp;<strong class="">&nbsp;</strong></p>
                    <p class="">&nbsp; &nbsp; 4.4 . Any amount received by SKILLWINZ as Designated Amount for participation in a Contest shall be non-refundable, except in the event that a Contest has been cancelled.<strong class="">&nbsp;</strong></p>
                    <p class="">&nbsp; &nbsp; 4.5 . Contests shall be announced by the SKILLWINZ from time to time in any manner that is convenient to the Company, and SKILLWINZ reserves the right to extend the participation and time limit for registration, cancel any Contest, and modify any terms, all with due notice to the Users of the Platform.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">4.6 Winnings</strong></i></p>
                    <p class="">&nbsp; &nbsp; a) Awards for all Contests shall be pre-declared at the time of registration in the specific rules applicable to such Contest. Awards may be in the form of SKILLWINZ Tokens, cash or promotional gifts.</p>
                    <p class="">&nbsp; &nbsp; b) Users are eligible to withdraw only trade Winnings or cash Winnings from his/her Account to his/her respective bank/wallet account once it is validated and KYC (Know Your Customer) verified.</p>
                    <p class="">&nbsp; &nbsp; i. Please ensure Your bank account / UPI ID are active, failing which, We will be unable to process the withdrawal; and</p>
                    <p class="">&nbsp; &nbsp; ii. In case of withdrawal through a bank account or UPI, please ensure that the name on Your KYC document matches / is the same as the name of the account holder submitted for withdrawing Winnings. In the event the name on the KYC document does not match with the account holder’s name, the same shall be considered invalid. In such an event, You may retry adding your bank account details / UPI ID to ensure the name matches with the name on your KYC document.</p>
                    <p class="">&nbsp; &nbsp; c)&nbsp;<strong class="">KYC verification is mandatory as a proof of identity for making withdrawals from Your Account to Your bank/wallet account. KYC verification may be done through the Platform by providing certain details: (i) photograph of a valid government ID (Aadhaar Card, PAN Card etc.); and (ii) other User Details.</strong></p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">If you opt to provide your Aadhar card / Number for completion of KYC, you hereby:</strong>&nbsp;<strong class="">(i) Understand and agree that your Aadhar has been submitted only for completion of Your KYC verification; and (ii) You authorise Us to temporarily access Your Aadhar card and You understand that We will not be storing Your Aadhar number unless as prescribed by law.</strong></p>
                    <p class="">&nbsp; &nbsp; d) SKILLWINZ does not issue receipts for entries in the Contests. The entries are accounted within the Account on the Platform. It is the responsibility of the Users to ensure that the entries are accurate and the Winnings are duly credited to his Account.</p>
                    <p class="">&nbsp; &nbsp; e) Any tax/levies/duty etc., as applicable on material prizes, shall be paid by the winner of the game. Based on TDS (tax deducted at source) Rules for any sort of game winning in India, 31.2% TDS should be deducted on any winning above Rs.10,000/- in a single game.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">4.7 Return, Refund and Cancellation</strong></i></p>
                    <p class="">&nbsp; &nbsp; a) In the event, that there is an error in the Services provided by us, we may refund the Designated Amount paid, provided the reasons are genuine and proved after investigation by SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; b) Please read the rules of each Mobile Game and Contest before participating.</p>
                    <p class="">&nbsp; &nbsp; c) We do not cancel registrations once entered, however, in case of exceptional circumstances wherein the fault may lie with the payment gateway or from Our side, We will cancel your participation on request and refund the Designated Amount to You within a reasonable amount of time.</p>
                    <p class="">&nbsp; &nbsp; d) In case we cancel your participation in any Mobile Game or Contest as a result of this, We&nbsp; will return Your Designated Amount to You within a reasonable amount of time for You to redeem the same by playing other Contests on SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; e) We will try Our best to create the best user experience for You. If paid by credit card, refunds will be issued to the original credit card provided at the time of purchase and in case of payments made through a payment gateway, payment refunds will be made to the same account.</p>
                    <p class="">&nbsp; &nbsp; 4.8 A) Users must use any money in their Account within 365 days. SKILLWINZ shall have the right to directly forfeit any such unused amount after 365 (three hundred and sixty five) days with effect from 01 April 2023 (“<strong class="">Effective Date”</strong>) in accordance with the below mentioned terms.&nbsp; SKILLWINZ does not permit any cash withdrawal against any idle balance lying in any user’s SKILLWINZ account except in accordance with applicable law</p>
                    <p class="">&nbsp; &nbsp; 4.8 B. Account Maintenance Fees (“<strong class="">Maintenance Fees</strong>”)</p>
                    <p class="">&nbsp; &nbsp; As on Effective Date, if a User’s Account is lying dormant for a period of 365 days, SKILLWINZ shall charge an Account Maintenance Fees to such User which shall be computed as follows.</p>
                    <p class="">&nbsp; &nbsp; i) For Deposit Account: The entire amount lying in the Deposit Account which shall be inclusive of any indirect taxes.</p>
                    <p class="">&nbsp; &nbsp; ii) For Winnings Account: SKILLWINZ will charge a upfront freezing fee (“Upfront Fee”) of INR X for all inactive accounts. X will be equal to the amount lying in the Winnings wallet of such inactive account subject to a maximum cap of INR 100. Amount X shall be inclusive of all taxes.</p>
                    <p class="">&nbsp; &nbsp; The Maintenance Fee is for defraying the technical costs involved in such freezing, holding any amounts in escrow for users etc and SKILLWINZ shall not be required to provide any warning before charging the Account Maintenance Fees. SKILLWINZ shall however issue a tax invoice to the User while charging the Account Maintenance Fees to such User.</p>
                    <p class="">&nbsp; &nbsp; In case, the User’s Account continues to be dormant beyond a period of 365 days, SKILLWINZ also reserves the right to charge monthly maintenance fees (“<strong class="">Monthly Maintenance Fee</strong>”) which shall be charged on the balance remaining in the Winnings Account of the User after deduction of the Upfront Fee (“<strong class="">Balance</strong>”). The Monthly Maintenance Fee shall be charged in monthly arrears starting 30 (thirty) days after the completion of 365 (three hundred and sixty-five) days of dormancy. The Monthly maintenance fee shall be equal to 8.33% of Balance and shall be inclusive of all taxes. The User shall not be given any warning before the charge of Monthly Maintenance Fee. SKILLWINZ shall however issue a tax invoice to the User while charging the Account Maintenance Fees to such User.</p>
                    <p class="">&nbsp; &nbsp; The User shall incur this Monthly Maintenance Fee until his / her account is re-verified by SKILLWINZ and unfrozen.</p>
                    <p class="">&nbsp; &nbsp; The User can unfreeze his / her account simply by logging in to his / her account and re-submitting KYC documents required by SKILLWINZ for re-verification of the account.</p>
                    <p class="">&nbsp; &nbsp; The User acknowledges and agrees that this measure is designed to prevent fraud / identity theft and is required to protect the User’s information and account. The Monthly Maintenance Fee is for defraying the technical and manpower costs involved in re-verification of the User’s account, holding amounts in escrow etc.</p>
                    <p class="">&nbsp; &nbsp; In case, the User does not unfreeze his account before the Balance becomes nil, the User’s account will be permanently blocked. The User will no longer be able to access this account and information associated with this account, however, this would not prevent the User from creating a new account on SKILLWINZ by following the process listed in this Terms of Use.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">4.9 Bonus Program- Terms &amp; Conditions</strong></i></p>
                    <p class="">&nbsp; &nbsp; 4.9.1 As part of its Business Promotion strategy, SKILLWINZ may run a Bonus Coin Promotion which is a Discount Program whereby the User is allowed to accumulate discount eligibility points (referred to as ‘Bonus Coin’) in a separate wallet referred to as ‘Bonus Wallet’.</p>
                    <p class="">&nbsp; &nbsp; 4.9.2 Such Bonus Coins are granted as per various business promotion strategies that may be run by SKILLWINZ from time to time.</p>
                    <p class="">&nbsp; &nbsp; 4.9.3 The Bonus Coins are subject to control by SKILLWINZ and the Company is free to put such restrictions on its availability, utilisation, inherent discount benefits, time validity, etc. as it may decided from time to time.</p>
                    <p class="">&nbsp; &nbsp; 4.9.4 SKILLWINZ may allow upfront Cash Discount from the Platform Fee in lieu of the accumulated Bonus Coins in accordance with the conversion ratio as the Company may be decided from time to time for each Contest.</p>
                    <p class="">&nbsp; &nbsp; 4.9.5 All Bonus Coins credited in the User account shall be valid for a period of 30 days from the date of credit. If the Bonus Coins are not utilised by the User over a period of 30 days or if the User is inactive on SKILLWINZ for a period of 30 days, unutilised Bonus Coins shall be automatically expired by SKILLWINZ&nbsp;&nbsp;&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 4.9.6 In the event of termination or blocking of a User’s account, any Bonus Coins granted to the user until such time shall expire immediately and the User shall not have any right or claim in respect of the Bonus Coins.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">4.10 SKILLWINZ Cards</strong></i></p>
                    <p class="">&nbsp; &nbsp; 4.10.1 SKILLWINZ Cards is a feature in which the user needs to collect cards by doing certain tasks such as depositing money and playing games and then form sets and redeem them for real cash.</p>
                    <p class="">&nbsp; &nbsp; 4.10.2 If a user collects all 9 cards and redeem them, he/she wins Rs 500 and enters the lucky draw contest where 3 random users are selected and a prize money of 9 lakhs is given out in total, split across the users at SKILLWINZ’s discretion.</p>
                    <p class="">&nbsp; &nbsp; 4.10.3 Post the winner announcements, SKILLWINZ will try to contact the winners for 3 working days. If any of the winner(s) is not contactable, they shall be replaced with new winner(s) for that rank.</p>
                    <p class="">&nbsp; &nbsp; 4.10.4 If the announced winner gets blocked before contacting, a new winner will be announced.</p>
                    <p class="">&nbsp; &nbsp; 4.10.5 Winners would have to submit their own PAN and bank account details mandatorily. Submitting details of someone else’s bank account will result in cancellation of the prize money.</p>
                    <p class="">&nbsp; &nbsp; 4.10.6 Once the winners have given their details, it can take a maximum of 15 days to get the money credited into their account.</p>
                    <p class="">&nbsp; &nbsp; 4.10.7 SKILLWINZ reserves the right to modify the amount given out to winners anytime, without giving prior notice.</p>
                    <p class="">&nbsp; &nbsp; 4.10.8 SKILLWINZ will credit the money directly into the bank account of the winners and not the SKILLWINZ wallet.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">4.11. SKILLWINZ TOKENS</strong></i></p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class="">4.11.1 SKILLWINZ Tokens accumulated by a User are reflected in their Wallet on the Platform as ‘Token Balance.</i></p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class="">4.11.2 SKILLWINZ Tokens are granted as per various business promotion strategies that may be run by SKILLWINZ from time to time. SKILLWINZ Tokens are subject to control by SKILLWINZ, and SKILLWINZ is entitled to put such restrictions on its availability, utilisation, inherent discount benefits, time validity, etc. as it may decide from time to time without any requirement for further notice or intimation.</i></p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class="">4.11.3. As part of improvements to the Platform, SKILLWINZ shall discontinue issue/ award of new SKILLWINZ Tokens from 20 December 2021.</i></p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class="">4.11.4. From 20 December 2021, all SKILLWINZ Tokens accumulated in a User’s Wallet shall be revoked and the SKILLWINZ Token balance will stand at Nil.</i></p>
                    <h4 class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Mobile Games</strong></i></h4>
                    <p class="">&nbsp; &nbsp; 5.1 . The Platform is operated and designed to provide You an in-app browsing experience through an embedded browser. The Platform is per se a platform which offers Services and hosts Mobile Games by Third Party Game Developers. The Platform does not host, display or transmit any content owned by third parties on its servers, unless We either have a license or are not prohibited under the Applicable Laws, to do the same.</p>
                    <p class="">&nbsp; &nbsp; 5.1.2 . The Mobile Games will have their own rules and code of conduct (“<strong class="">Game Rules</strong>”) which shall be adhered to by all Users at all times.&nbsp;&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 5.1.3 . When You play a Mobile Game, You agree and acknowledge that the Platform does not create and/or broadcast any content on its own accord and SKILLWINZ is not responsible or liable for the content or accuracy of the Mobile Game, including copyrights, that may be accessed by You through the Platform.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Games of skill</strong></i></p>
                    <p class="">&nbsp; &nbsp; 6.1 . The Mobile Games hosted by SKILLWINZ on the Platform are all Games of Skill (<i class="">defined below</i>). Different Mobile Games will reward different skills, but each game will reward certain skills, such as knowledge of the game, familiarity with rules, experience, reflexes, experience, practice, hand-eye coordination etc. Certain games may have pre-determined outcomes (Sudoku, Crosswords, Brick Breaker), which are achievable by the Users using their skills to play the games.</p>
                    <p class="">&nbsp; &nbsp; 6.2 . Users must note that all Mobile Games available on the Platform are games where the success or/and performance of the Users depends predominantly upon their superior knowledge, training, attention, experience and adroitness (“<strong class="">Games of Skill</strong>”) in playing the game. ‘Games of Skill’, under Indian law, are excluded from the ambit of gambling legislations including, the Public Gambling Act of 1867 and other state enactments except in the Restricted States.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 6.3 . Hence, the individuals residing in the Restricted States should not participate in any Contest or Mobile Games offered on the Platform. Any participation by such individuals shall be at their sole risk and discretion and SKILLWINZ shall not be liable to refund or reimburse any injury or damages suffered by such individuals.</p>
                    <p class="">&nbsp; &nbsp; 6.4 . SKILLWINZ reserves the right to monitor all activities from the User’s Account on the Platform. If any User participates in any Contest or Mobile Games in violation of any central and/or state law(s) in India, SKILLWINZ reserves the right to cooperate with law enforcement agencies of the relevant state and report such participation or/and suspicious activity along with all relevant details of the concerned User. The users based out of Restricted States that are not permitted under applicable Laws to participate in specific or all games hosted on the Platform and shall not be permitted to connect to the Game and/or Platform by breaching security measures put in by the Company, including without limitation through a virtual private network.</p>
                    <p class="">&nbsp; &nbsp; 6.5 . SKILLWINZ does not support, endorse or offer to any User ‘games of chance’ for money. Each Mobile Game and Contest available on the Platform has clearly defined rules and code of conduct. All Users are encouraged to read, understand and follow these rules to be successful in these games. SKILLWINZ shall not be liable if Users do not adhere to the Game Rules or otherwise engage in gambling or betting activities on the Platform.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Safety precaution and responsible gaming</strong></i></p>
                    <p class="">&nbsp; &nbsp; 7.1 .&nbsp;<strong class="">MPL suggests that Users adopt a balanced approach while engaging with any of the Mobile Games on the Platform and safeguard themselves against any adverse effects. If played responsibly, Mobile Games (like other forms of sports) aim to encourage Users to develop their mental prowess, hand-eye co-ordination, competitiveness and encourage teamwork.</strong></p>
                    <p class="">&nbsp; &nbsp; 7.2 . You should take certain standard health and safety precautions while playing any Mobile Game, including taking adequate breaks, sitting at a reasonable distance from the screen, playing in a well-lit environment, and avoiding playing when tired, drowsy, intoxicated or under the influence of drugs Avoid playing without breaks over long periods of time as this could affect Your performance and could negatively impact hand-eye coordination, balance, and multi-tasking ability.&nbsp;&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 7.3 .&nbsp;<strong class="">The Platform or the Mobile Games offered on the Platform may contain flashing lights, realistic images, patterns and simulations which may cause certain side effects. If Your hands, wrists, arms, eyes or other parts of Your body become tired or sore while playing, or if You feel symptoms such as tingling, numbness, burning or stiffness, stop and rest before playing again. If You continue to have any of these above symptoms, please stop playing IMMEDIATELY and consult a doctor.</strong></p>
                    <p class="">&nbsp; &nbsp; 7.4 . If You play any Mobile Games on the Platform, give Yourself plenty of room to play and always be aware of Your surroundings. While playing any Mobile Game You may be moving around the play area and using Your hands to control gameplay. You must ensure that You are not near other people, objects, stairs, balconies, windows, walls, furniture or other objects that may pose a danger to You or could be damaged during the play. Never handle sharp or dangerous objects while playing any Mobile Game on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 7.5 .&nbsp;<strong class="">It is Your responsibility to control Your surroundings and movements at all times when playing the game to ensure that You don’t injure Yourself, any other persons, damage any property etc. Any playing of the Mobile Games is at Your own risk and SKILLWINZ shall not be liable for any death, injury or health complications resulting from Your use of the Platform.</strong></p>
                    <p class="">&nbsp; &nbsp; 7.6 . You agree that Your use of the Platform and to play the Mobile Games is at Your own risk, and it is Your responsibility to maintain such health, liability, hazard, personal injury, medical, life, and other insurance policies as You deem reasonably necessary for any injuries that You may incur while using the Platform.</p>
                    <p class="">&nbsp; &nbsp; 7.7 . You hereby agree that if the terms of this Agreement are not adhered by You, SKILLWINZ will be irreparably damaged, and therefore You agree that the Platform shall be entitled, without bond, other security, proof of damages, to appropriate equitable remedies with respect to any breach of this Agreement, in addition to any other remedies available under Applicable Law.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Community rules and code of conduct</strong></i></p>
                    <p class="">&nbsp; &nbsp; 8.1 . SKILLWINZ may provide Users with different communication channels such as forums, live audio sessions, live audio chat with other players participating in any Contest, community groups, chat-rooms or chat areas (“<strong class="">Communication Facilities</strong>”) designed to enable You to communicate with other Users on the Platform.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 8.2 . SKILLWINZ only provides Users with the technical environment for an exchange of information. SKILLWINZ does not initiate, select the receiver and/or modify any information exchanges inter se amongst the Users through the Communication Facilities. Further, SKILLWINZ has no obligation to and does not monitor these Communication Facilities except&nbsp; when required to do so under the provisions of Applicable Law</p>
                    <p class="">&nbsp; &nbsp; 8.3 . The User may use the Communication Facilities as made available to them by the Platform. SKILLWINZ may provide certain additional features or Communication Facilities to a select group of Users at its sole discretion. The Users do not have the right to claim any Communication Facilities on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 8.4 . SKILLWINZ may enable audio sessions and live chat-rooms on the Platform for the Users from time-to-time. These sessions or/and chat rooms will be conducted by other users to (i) organize trainings; (ii) provide tips to play Mobile Games; (iii) provide educational content; or (iv) conduct other interactive sessions on the Platform. The sessions or/and chat rooms may have an open entry or may be restricted to a limited number of Users at SKILLWINZ’s sole discretion. Users might be required to pay an ‘Access fee’ to participate in any of these live sessions or/and chat rooms hosted by SKILLWINZ on the Platform. The ‘Access Fee’ shall be subject to levy of applicable Goods &amp; Services Tax and the Fee collected shall be inclusive thereof.</p>
                    <p class="">&nbsp; &nbsp; 8.5 . You understand that, by using these Communication Facilities, You may be exposed to communications (including in written, verbal, electronic, digital, machine-readable or other form) that You might find objectionable. You understand that any content sent or appearing through the Communication Facilities is the sole responsibility of the User(s) transmitting such content and SKILLWINZ is not liable for any content posted on the Communication Channels.</p>
                    <p class="">&nbsp; &nbsp; 8.6 . All Users of the SKILLWINZ Platform must comply with Applicable Law at all times while using the Services of Platform, including our Community Guidelines (refer to Annexure III for more details) and Communication Facilities and must not publish or distribute any content which: (a) violates an Applicable Law, goes against common decency, or breaches the general terms and conditions of the Agreement; or (b) violates any trademarks, patents, utility or design patterns, copyrights, trade secrets, or other proprietary rights of third parties; or (c) impersonate any real person, including without limitation SKILLWINZ or any other SKILLWINZ agent or employee; or (d) is obscene, racist, violent, pornographic, of adult content, or otherwise threatening to the development of children and adolescents, or of a detrimental nature; or (e) is of an abusive, harassing, threatening or defamatory nature; or (f) seeks to unlawfully obtain login information or access to Accounts belonging to other Users; (g) contains personal data of third parties without their explicit consent; or (h) seek to collect, misuse, or disseminate any other User’s content or non-public personal information; or (i) is commercial, in particular promotional, in nature; (j) encourages or promotes gambling or money-laundering.</p>
                    <p class="">&nbsp; &nbsp; 8.7 . All Users of the platform of SKILLWINZ shall read and fully understand our Community Guidelines before posting any content on our platform.&nbsp; By accepting the Terms and Use, the Users agree and acknowledge that they have read and fully understood the terms of the Community Guidelines.&nbsp; SKILLWINZ has a right to take appropriate and strict actions against any User violating such Community Guidelines.</p>
                    <p class="">&nbsp; &nbsp; 8.8. Under no circumstances will SKILLWINZ or its third-party providers shall be liable for any errors, objections or omissions in any content or for any loss or damages of any kind incurred as a result of the content posted on its Platform.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Restrictions on your use of the Platform</strong></i></p>
                    <p class="">&nbsp; &nbsp; 9.1 . You will not use the Platform, or any content provided thereof for any purpose that is illegal, unlawful or prohibited by this Agreement or under Applicable Law.</p>
                    <p class="">&nbsp; &nbsp; 9.2 . You will not use the Platform, or any content provided thereof:</p>
                    <p class="">&nbsp; &nbsp; 9.2.1 . to host, display, upload, modify, publish, transmit, update or share any information that belongs to another Person and to which the User does not have any right to;&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 9.2.2 . is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging,</p>
                    <p class="">&nbsp; &nbsp; 9.2.3 . that is relating to or encourages money laundering or gambling, or is otherwise unlawful in any manner whatsoever;</p>
                    <p class="">&nbsp; &nbsp; 9.2.4 . that harms minors in any way;</p>
                    <p class="">&nbsp; &nbsp; 9.2.5 . that infringes any patent, trademark, copyright or other proprietary rights; violates any Applicable Law for the time being in force;&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 9.2.6 . that deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</p>
                    <p class="">&nbsp; &nbsp; 9.2.7 . which impersonates another Person; contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of the Platform or&nbsp; any other computer resource;</p>
                    <p class="">&nbsp; &nbsp; 9.2.8 . that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation; threatens public health or safety; promotion of cigarettes or any other tobacco products or consumption of intoxicant including alcohol and Electronic Nicotine Delivery System (ENDS) and like products that enable nicotine delivery except for the purpose &amp; in the manner and to the extent, as may be approved under the Drugs and Cosmetics Act, 1940 and Rules made thereunder; threatens critical information infrastructure.</p>
                    <p class="">&nbsp; &nbsp; 9.3 . You will not redistribute, sublicense, rent, publish, sell, assign, lease, market, transfer, or otherwise make the Platform or any component or content thereof, available to third parties without the permission of SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; 9.4 . You will not circumvent or disable any digital rights management, usage rules, or other security features of the Platform; remove, alter, or obscure any proprietary notices (including copyright notices) on any portion of the Platform; and not use the Platform in a manner that threatens the integrity, performance, or availability of the Platform.</p>
                    <p class="">&nbsp; &nbsp; 9.5 . You will not attempt to or engage in any activity that may:</p>
                    <p class="">&nbsp; &nbsp; 9.5.1 . reverse engineer, decompile or otherwise extract the source code related to the Platform or any part thereof, unless it is expressly permitted by SKILLWINZ to You in writing or is required by the Applicable Law;</p>
                    <p class="">&nbsp; &nbsp; 9.5.2 . use any robot, spider, retrieval application, or other device to retrieve or index any portion of the Platform or content thereof;</p>
                    <p class="">&nbsp; &nbsp; 9.5.3 . collect information about Users for any illegal or unlawful purpose;</p>
                    <p class="">&nbsp; &nbsp; 9.5.4 . create any Account by automated means or under false or fraudulent pretences for using the Platform;</p>
                    <p class="">&nbsp; &nbsp; 9.5.5 . transmit any viruses, worms, defects, trojan horses, or any items of a destructive nature through the Platform;</p>
                    <p class="">&nbsp; &nbsp; 9.5.6 . use the Platform in any manner that could damage, disable, overburden, or impair, or undertake any action which is harmful or potentially harmful to, any of the servers, networks, computer systems or resources connected to any of the servers connected, directly or indirectly to the Platform, or interfere with any other Users’ use and enjoyment of the Platform;</p>
                    <p class="">&nbsp; &nbsp; 9.5.7 . carry out any denial of service (DoS, DDoS) or any other harmful attacks on the Platform or; disrupt or place unreasonable burdens or excessive loads on, or interfere with or attempt to make, or attempt any unauthorized access to the Platform or any part of the Platform or any User of the Platform;</p>
                    <p class="">&nbsp; &nbsp; 9.5.8 . forge headers or otherwise manipulate identifiers to disguise the origin of any content transmitted through the Platform; and/or</p>
                    <p class="">&nbsp; &nbsp; 9.5.9 . obtain any materials or information through any means not intentionally made available, in the opinion of SKILLWINZ, through the Platform.</p>
                    <p class="">&nbsp; &nbsp; 9.6 . You will not impersonate another Person or impersonate, guide or host on behalf of, or falsely state or otherwise misrepresent Your affiliation with any Person or entity, including, but not limited to Our officials, employees, agents, partners, affiliates, dealers and franchisees.</p>
                    <p class="">&nbsp; &nbsp; 9.7 . If We detect any activity from Your User Account that is fraudulent, suspected to be defrauding the Platform, or if You are found colluding or participating in any kind of fraudulent activity on the Platform in any manner, then You shall be debarred from participating in any Contest available on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 9.8 . We reserve the right to deactivate or delete Your Account and all related information on it and forfeit related Balances and/or take any other appropriate action, at our sole and absolute discretion, in the event of any fraudulent, illegal or suspicious activities from Your Account on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 9.9 . Any deductions or forfeiture of Balances made from Your Account shall be made at Our sole and absolute discretion and shall not result in any liability on SKILLWINZ whatsoever. We may choose to provide a right of appeal to the User after deleting, closing or forfeiting the balances in his/her Account. However, such right shall be provided at the sole and absolute discretion of SKILLWINZ and the User shall not claim such right or any other recourse from SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; 9.10 .&nbsp;<i class=""><strong class="">Indemnification:&nbsp;</strong></i>You shall defend, indemnify and hold SKILLWINZ, and its officers, directors, employees, representatives and agents harmless from and against any claims, actions, demands, liabilities, judgments, and settlements, including without limitation, reasonable legal fee that may result from or alleged to result from (a) Your use of the Platform or any content thereof, including Mobile Game; or (b) Your breach of any rules, regulations and/or orders under any applicable law.</p>
                    <p class="">&nbsp; &nbsp; 9.11 . You are also responsible for any breach of Your obligations under the Agreement and/or for the consequences of any such breach.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Termination</strong></i></p>
                    <p class="">&nbsp; &nbsp; 10.1 . Your access to the Platform may be terminated if:</p>
                    <p class="">&nbsp; &nbsp; 10.1.1 . You voluntarily uninstall the Platform from Your device;</p>
                    <p class="">&nbsp; &nbsp; 10.1.2 . You knowingly or unknowingly cause direct or indirect breach, as ascertained by SKILLWINZ, of these Terms of Use or Privacy Policy as a whole or in part; or</p>
                    <p class="">&nbsp; &nbsp; 10.1.3 . You do not pay the requisite fee, if any, should SKILLWINZ charge for use of the App.</p>
                    <p class="">&nbsp; &nbsp; 10.2 . We may have to terminate Your access to the Platform if:</p>
                    <p class="">&nbsp; &nbsp; 10.2.1 . We are required to do so by law (for example, where the access to and/or provision of the Platform to You becomes, unlawful);</p>
                    <p class="">&nbsp; &nbsp; 10.2.2 . The third-party Person, if any, with whom We offered the Platform to You has terminated its relationship with Us or ceased to offer the related services to Us or to You;</p>
                    <p class="">&nbsp; &nbsp; 10.2.3 . The provision of Platform to You, is no longer commercially viable or feasible for Us; or</p>
                    <p class="">&nbsp; &nbsp; 10.2.4 . You are a repeat infringer of this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 10.3 . We may terminate this Agreement at any time, with or without notice and may disable Your access to the Platform and/or bar You from any future use of the Platform.</p>
                    <p class="">&nbsp; &nbsp; 10.4 . You may terminate this Agreement at any time by terminating Your access to the Platform. However, certain obligations of Yours under this Agreement shall continue to prevail even on such termination.</p>
                    <p class="">&nbsp; &nbsp; 10.5 . When this Agreement comes to an end, all of the legal rights, obligations and liabilities that You and SKILLWINZ have benefited from, been subject to (or which have accrued over time whilst the Agreement has been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and shall continue to apply to such rights, obligations and liabilities indefinitely.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Disclaimers</strong></i></p>
                    <p class="">&nbsp; &nbsp; 11.1 .&nbsp;<i class=""><strong class="">No warranties:&nbsp; </strong></i>The Contents provided through the Platform may include technical inaccuracies or typographical errors. SKILLWINZ may make changes or improvements to the Platform at any time. The Contents or any information available on the Platform is provided “as is” and “as available” and without warranties of any kind either expressed or implied, to the fullest extent permissible pursuant to Applicable Laws, including but not limited to:</p>
                    <p class="">&nbsp; &nbsp; 11.1.1 . any implied warranty or merchantability, fitness for a particular purpose;</p>
                    <p class="">&nbsp; &nbsp; 11.1.2 . that the contents of the Platform will be uninterrupted or error-free;</p>
                    <p class="">&nbsp; &nbsp; 11.1.3 . that defects, if any on the Platform will be corrected;</p>
                    <p class="">&nbsp; &nbsp; 11.1.4 . the Platform being constantly available or available at all;</p>
                    <p class="">&nbsp; &nbsp; 11.1.5 . installation or uninstallation choices in relation to the Platform being successfully executed in all cases;</p>
                    <p class="">&nbsp; &nbsp; 11.1.6 . Your personal ability to use the Platform;</p>
                    <p class="">&nbsp; &nbsp; 11.1.7 . Your satisfaction with the use of the Platform;</p>
                    <p class="">&nbsp; &nbsp; 11.1.8 . the accuracy of the data provided by the Platform;</p>
                    <p class="">&nbsp; &nbsp; 11.1.9 . the security and privacy of Your data;</p>
                    <p class="">&nbsp; &nbsp; 11.1.10 . that all bugs or errors in relation to the Platform will be corrected;</p>
                    <p class="">&nbsp; &nbsp; 11.1.11 . that the Platform will be compatible with all devices and all networks;</p>
                    <p class="">&nbsp; &nbsp; 11.1.12 . that the Platform is fit for a particular purpose or use;</p>
                    <p class="">&nbsp; &nbsp; 11.1.3 . that the Platform and the contents thereof are accessible in every location; or</p>
                    <p class="">&nbsp; &nbsp; 11.1.4 . that the Platform and the contents would be perpetually secure and not prone to breaches of security anywhere in the world.</p>
                    <p class="">&nbsp; &nbsp; 11.2 . SKILLWINZ, its officers, directors, employees, affiliates and agents and any other service provider(s) responsible for providing access to the Platform in connection with this Agreement will not be liable for any acts or omissions, including of a third party Person, and including those vendors participating in SKILLWINZ’ offerings made to You, or for any unauthorized interception of data or breaches of this Agreement attributable in part to the acts or omissions of third parties Persons, or for damages that result from the operation systems, equipment, facilities or services provided by third parties Persons that are interconnected with SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; 11.3 . The Platform may include links to other mobile applications and/or websites (some of which may be automated search results) which may contain materials that are objectionable, unlawful, or inaccurate. We do not endorse or support these links or the products and services they provide; these links are provided for Your convenience only. We are not responsible or liable for the content or accuracy of such links.</p>
                    <p class="">&nbsp; &nbsp; 11.4 . We may stop provision of the Platform (or any part thereof), permanently or temporarily, to You or to Users generally or may modify or change the nature of the Platform and/or these Terms of Use at Our sole discretion, without any prior notice to You. Your use of the Platform following any such modification constitutes Your deemed acceptance to be bound by the Agreement (or as it may be modified).</p>
                    <p class="">&nbsp; &nbsp; 11.5 . You agree that You are responsible for all data charges You incur through use of the Platform.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">11.6 .</strong></i>&nbsp;<i class=""><strong class="">Sponsored Content:&nbsp;</strong></i></p>
                    <p class="">&nbsp; &nbsp; 11.6.1 . You agree and acknowledge that SKILLWINZ may, pursuant to its engagement with certain Sponsored Content Partners, place certain Sponsored Content on, about, or in conjunction with the other content within the Platform. The manner, mode and extent of display of such Sponsored Content is subject to change without a specific notice to You. We don’t promise to but will try Our best to give You a prior notice in this regard.</p>
                    <p class="">&nbsp; &nbsp; 11.6.2 . The content displayed on the Platform is for Your non-commercial and personal use. However, Sponsored Content Partners may use the Platform to display their respective Sponsored Content for commercial purposes. You are not allowed to copy, reproduce, alter, modify, create derivative works of, or publicly display any content displayed on the Platform.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">11.7 .&nbsp;</strong><i class=""><strong class="">Limitation of liability</strong></i><strong class="">:&nbsp;</strong>Your use of the Platform, or any content, including Mobile Game, available thereof is entirely at Your own option and risk and We shall be held harmless and not be liable for any direct, indirect, incidental, consequential, special, exemplary, punitive, monetary or any other damages, fees, fines, penalties or liabilities whatsoever arising out of or relating to Your use of the Platform or any content thereof, including Mobile Game. To the maximum extent permitted by Applicable Law, Our total liability to You for any damages (regardless of the foundation for the action) shall not exceed in the aggregate the Balance in your Account.</p>
                    <p class="">&nbsp; &nbsp; 11.8 . You may terminate Your access to the Platform if You are not satisfied with the Platform or any Mobile Games.</p>
                    <p class="">&nbsp; &nbsp; 11.9 .&nbsp;<i class=""><strong class="">Force Majeure</strong>:</i> SKILLWINZ shall not be liable for any damages whatsoever arising out of Force Majeure Event or other similar circumstances, directly or indirectly to the Users as a result of playing Mobile Games or using the services offered by SKILLWINZ on the Platform. A Force Majeure Event is a real or potential labour disputes, governmental actions, war or threat of war, sabotage, civil unrest, demonstrations, fire, storm, flooding, explosion, earthquake, provisions or limitations of materials or resources, inability to obtain the relevant authorization, accident, and defect in electricity or telecommunication network that affects the Company’s ability to offer the services or/and the Mobile Games on the Platform. Force majeure or other event beyond SKILLWINZ’s control hindering, delaying or complicating the maintenance of the Platform entitles SKILLWINZ to suspend or limit the Platform until further notice.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Governing law and dispute resolution</strong></i></p>
                    <p class="">&nbsp; &nbsp; 12.1 . The Platform may be controlled and operated through any country and will be subject to the laws of that state in which they are controlled and operated by the User. If You use the Platform from any location, then, You are responsible for compliance with the local laws applicable to You.</p>
                    <p class="">&nbsp; &nbsp; 12.2 . All or any disputes arising out of or touching upon or in relation to this Agreement including the interpretation and validity of the terms thereof and the respective rights and obligations of the parties shall be settled through arbitration. The arbitration shall be governed by the Arbitration &amp; Conciliation Act, 1996 (India) or any statutory amendments/modifications thereto for the time being in force.</p>
                    <p class="">&nbsp; &nbsp; 12.3 . The arbitration proceedings shall be held at Jaipur, Karnataka by a sole arbitrator who shall be appointed by SKILLWINZ and whose decision shall be final and binding upon all the parties.</p>
                    <p class="">&nbsp; &nbsp; 12.4 . Subject to the arbitration clause, the courts at Jaipur, Karnataka alone shall have the jurisdiction. The language of the arbitration proceedings shall be in English.</p>
                    <p class="">&nbsp; &nbsp; 12.5 . You and SKILLWINZ agree that any cause of action arising out of Your use of the Platform must be commenced within 3 (three) months after the cause of action accrues or You become aware of the facts giving rise to the cause of action, whichever is later. Otherwise, such cause of action shall be permanently barred.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Account Deletion:</strong></i></p>
                    <ol class="">
                        <li class="">&nbsp; &nbsp; You can delete Your Account at any time by following the process provided on the Platform (‘<strong class="">Account Deletion</strong>’). &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Once You opt for Account Deletion on the Platform, We will process this request as per the timeline communicated to you at the time of submission of the Account Deletion request. In all cases , we will confirm our acceptance of the Account Deletion request within 12 hours of making the request, and Your Account will be deleted within 15 days of acceptance.&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Please note that Account Deletion is irreversible. Once deleted, Your Account cannot be retrieved even if you want to come back onto the Platform or have deleted the Account by accident, including if Your Account has been hacked / You have lost control of Your Account.&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Please note that any Winnings that are not withdrawn from Your Account will lapse after Your Account Deletion – please ensure that any Winnings in your Wallet are withdrawn from Your Account prior to opting for Account Deletion; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Here is what happens to Your Profile Information once Your Account is deleted, which will also have to be read with our&nbsp;<a href="/privacypolicy" class=""><span  class="">Privacy Policy</span></a>: &nbsp; &nbsp; &nbsp; &nbsp;
                            <ul class="">
                                <li class="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; The record of Account(s) that You follow, as well as the Account(s) that follow you will be permanently deleted; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</li>
                                <li class="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Subscriptions or additional services, if any, will lapse immediately; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</li>
                                <li class="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; All Bonus Coins accumulated in Your Account will lapse; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</li>
                                <li class="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Any Deposits left in Your Account at the time of deletion will be credited to the original payment method within 15 (fifteen) working days from the date of deletion of Your Account. The Deposits will be credited via the same payment instrument(s) as the ones used for depositing cash in Your Deposit Wallet. It is clarified that if the payment instrument associated with Your Account (such as the UPI ID or bank account used) has expired, then such Deposits will be forfeited to Us. We recommend that You update the payment method prior to submitting a deletion request;Subject to (d) above, any Winnings (including bonus cash, tickets, cashback) left in Your Account at the time of deletion will lapse after deletion of Your Account; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</li>
                                <li class="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Once Your Account is successfully deleted, You will not be able to make a new account on the Platform using the same login details. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</li>
                                <li class="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Details of transactions carried out in Your Account, including KYC verification details and withdrawal beneficiary details, may be retained under Applicable Law, including for the purposes listed in section 10 – ‘<i class="">Compliance, Disclosure, and Notices</i>’; and &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</li>
                                <li class="">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Your gameplay history may be retained as per Applicable Law, including for the purposes listed in section 10 – ‘<i class="">Compliance, Disclosure, and Notices</i><strong class="">’.</strong> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</li>
                            </ul>
                        </li>
                    </ol>
                     
                    <h4 class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Referral program</strong></i></h4>
                    <p class="">&nbsp; &nbsp; 1.SKILLWINZ  may offer referral programs (“Referral Program”) on the Platform to allow all its Users to receive certain rewards in the form of SKILLWINZ Tokens or cash (“Rewards”) for each player that they refer to play on the Platform</p>
                    <p class="">&nbsp; &nbsp; 2. All active Users of the Platform are eligible to participate in the Referral Program except in cases where an Account has been suspended or has otherwise been disallowed by SKILLWINZ from participation in the Referral Program.</p>
                    <p class="">&nbsp; &nbsp; 3. Only ‘successful referrals’ will be counted and eligible to receive the Rewards. A successful referral is an event in which a new User will register on the Platform and complete the sign-up by clicking on the referral link of the referrer or by putting the referrer’s unique referral code.</p>
                    <p class="">&nbsp; &nbsp; 4.&nbsp;<strong class="">Criteria to use the Rewards</strong> – The User will be able to use only a certain percentage of the cumulative Rewards (as permitted by SKILLWINZ from time to time) reflecting in his/her Account to join any of the upcoming tournaments on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 5<strong class="">. Additional terms applicable to all Referral Programs</strong></p>
                    <ol class="">
                        <li class="">&nbsp; &nbsp; The nature and value of Rewards to be credited will be based on the Referral Program offered by SKILLWINZ at any given point in time to an individual User. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Referral links will be generated from each User’s Account on the Platform. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Rewards and their types are subject to change at SKILLWINZ’s discretion without notice. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Users found to have been committing fraud will be blocked from the SKILLWINZ platform. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; The reward amount/type is based on the applicable reward on the day a user’s referral signs up on the platform. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; A user can refer up to 1000 friends. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; SKILLWINZ reserves the right to change rules and regulations without notice. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Please contact Customer Support in case of further queries.The nature and value of Rewards to be credited will be based on the Referral Program offered by SKILLWINZ at any given point in time to an individual User. &nbsp; &nbsp;</li>
                    </ol>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">6. Disqualification from Referral Programs</strong></p>
                    <ol class="">
                        <li class="">&nbsp; &nbsp; Users will not receive credit for referring themselves by creating multiple Accounts. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; SKILLWINZ reserves the right, in its sole discretion, to suspend or disqualify an Account from the Referral Program or nullify the referral Rewards. &nbsp; &nbsp;</li>
                    </ol>
                    <p class="">&nbsp; &nbsp; 7.&nbsp;<i class=""><strong class="">Taxes</strong>&nbsp;</i>– The Users are responsible for paying any and all taxes or commissions related to the Rewards earned by them through the Referral Programs on the Platform. SKILLWINZ reserves the right to withhold taxes as appropriate and as required under Applicable Laws.</p>
                    <h4 class="">&nbsp; &nbsp;&nbsp;<i class=""><strong class="">Special rules and principle for SKILLWINZ SuperTeam/SKILLWINZ </strong></i></h4>
                    <ol class="">
                        <li class="">&nbsp; &nbsp; Along with the Mobile Games developed by Third Party Game Developers, SKILLWINZ provides  sports to the Users on the Platform (“<strong class="">SKILLWINZ SuperTeam / SKILLWINZ </strong>”). SKILLWINZ SuperTeam/SKILLWINZ  is a brand owned and operated by SKILLWINZ. It is an online based  sport which allows its Users to pick their team made up of real players participating in a real sporting competition. Users play SKILLWINZ SuperTeam/SKILLWINZ  by using their knowledge and skill of the underlying actual sport. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Rules For&nbsp;<strong class="">SKILLWINZ Superteam / SKILLWINZ </strong> &nbsp; &nbsp;</li>
                    </ol>
                    <p class="">&nbsp; &nbsp; 2.1 . SKILLWINZ SuperTeam / SKILLWINZ  is an online  sports contest in which Users are invited to pay money to play the game, thereby allowing Users access to build and act as owners of online teams (consisting of real players or teams) that compete against online teams of other Users with results tabulated on the basis of statistics generated by the real individual sportspersons in certain designated professional sporting matches.</p>
                    <p class="">&nbsp; &nbsp; 2.2 . Users can participate in a Contest with other Users without any restriction on participation, unless specified otherwise in the Rules or the Agreement. Users can participate by choosing any of the upcoming Contests which will be linked to any of the current of upcoming real-world event.</p>
                    <p class="">&nbsp; &nbsp; 2.3 . All Users while selecting a  team (“<strong class=""> Team</strong>“) for any match are provided with the same budget. Any change in the budget shall be applied uniformly for all players. All Users must pick from the same list of players and are bound by the same rules while picking the composition of their respective teams. This means that all Users have the same maximum/minimum limits on the number of batsmen, bowlers, wicket-keeper and all-rounders, and limits are placed equally on the number of players that can be picked from a particular real-life team.</p>
                    <p class="">&nbsp; &nbsp; 2.4 . Users can make changes to their  Teams until the deadline of the registration for the Contest. Users can make a  Team, based on credits they select. Users can select a maximum of 7 players from one playing side team. Users can create up to a maximum of 6 unique teams for a single Contest.</p>
                    <p class="">&nbsp; &nbsp; 2.5 .  Teams are awarded points on the basis of the real-life sport’s players performances at the end of a designated match, match tournament of the Contests. The participants whose  Teams have achieved the highest aggregate scores in the Contests shall be declared winners. In certain pre-specified Contests, there may be more than one Winner and distribution of prizes to such Winners will be in increasing order of their team’s aggregate score at the end of the designated match of the Contests.</p>
                    <p class="">&nbsp; &nbsp; 2.6 . Users will not be offered the opportunity or option to auto-coSKILLWINZete or auto-fill their teams and/or responses within the Contest.</p>
                    <p class="">&nbsp; &nbsp; 2.7 . The ranks and credits of players keep fluctuating during a contest as the players perform in the real-life match. The  Team keeps earning points for Users’ chosen players performances.The points status appearing next to Users’ total points indicate if the match is in progress or completed.</p>
                    <p class="">&nbsp; &nbsp; 2.8 . SKILLWINZ shall ensure that all Contests offered to the Users participating in SKILLWINZ Super Team are competitive and incorporate principles of fair-play. SKILLWINZ is committed to ensuring the utmost levels of fairness and competitiveness in its SKILLWINZ SuperTeam/SKILLWINZ .</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp;&nbsp;<strong class="">Rules for  Pro under SKILLWINZ SuperTeam / SKILLWINZ </strong> &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                            <br class=""/>&nbsp; &nbsp; &nbsp; &nbsp; 3.1. Each User has the opportunity to get additional rewards over and above the Winnings in a particular Contest on the Platform (“<strong class="">Additional Winnings</strong>”) while participating in SKILLWINZ , by allowing players to create strong teams in a Contest and share them with other players participating in the same Contest. SKILLWINZ  Pro, one of the integral parts of the SKILLWINZ  ecosystem, is aimed at making the game more competitive by increasing opportunities for players to exercise their strategic prowess. &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                            <br class=""/>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                            <br class=""/>&nbsp; &nbsp; &nbsp; &nbsp; 3.2. ‘<strong class=""> Pro</strong>’ on SKILLWINZ allows each User participating in a Contest to play with a  Team created by another player based on their knowledge and skill in SKILLWINZ  (‘<strong class="">Additional Winnings</strong>’)’. This is as illustrated below: &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp;&nbsp;<strong class="">Registration:</strong> Player A and Player B register for an India versus New Zealand match (‘<strong class="">Contest</strong>’) by paying the SKILLWINZ Platform fee. After successful registration, Player A creates their  Team to participate in the Contest; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp;&nbsp;<strong class="">‘Buy’</strong>*: In order to gain additional winnings, Player A (‘Proposer’<strong class="">*</strong>) decides to provide his/her  Team (‘<strong class="">TradedTeam / Trade</strong>’<strong class="">*</strong>)&nbsp; to be shared with any other participant in the Contest on receipt of some minimum Additional Winnings. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp;&nbsp;<strong class="">‘Bid’*</strong>: Players registered in the Contest who strategize to participate in the Contest with Player A’s team (as Player A frequently features in leaderboards) offer Additional Winnings for Player A’s team. The highest Additional Winnings is offered by Player B, who is declared the new winner of Player A’s team.<strong class="">&nbsp;</strong>A Contender can edit or remove their offer of Additional Winnings prior to its acceptance by the Proposer. Player B’s prize offering becomes invalid when Player C offers to Player A a higher Additional Winnings amount than Player B. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp;&nbsp;<strong class="">‘Sell</strong>’*: Player B (‘Contender’<strong class="">*</strong>) successfully copies Player A’s team after completing the payment of the offered Additional Winnings, after which the Traded Team is re-registered to Player B’s account. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Participation in the Contest: Player B can participate in the Contest with the team copied from Player A. Player A on being declared winner of the Additional Winnings, will exit the Contest with such Additional Winnings only. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">*</strong>&nbsp;<i class="">the terms are used for marketing purposes and to aid ease of understanding for the players. For legal purposes,  ProT does not result in transfer of digital assets or any monetary transaction between the players.  Pro is an additional in-game feature to enhance the competitive environment and allow players to demonstrate their strategic prowess.</i></p>
                    <p class="">&nbsp; &nbsp; 3.3.&nbsp;<strong class="">Sharing window:</strong> Players can Propose or Copy Traded Team(s) only between the start and conclusion of the Contest. Sharing will be closed when the Contest moves to the ‘Results’ tab.</p>
                    <p class="">&nbsp; &nbsp; 3.4.&nbsp;<strong class="">Platform Fee:</strong> For each  Pro transaction, SKILLWINZ will charge a Platform Fee against the service provided by SKILLWINZ of removing the Traded Team from the Proposer’s account post verification of relevant details, and re-registering it in the Contender’s account.</p>
                    <p class="">&nbsp; &nbsp; 3.5. Where a Contest allows a player to register with multiple teams, a player can Propose each of the registered teams created by them. For example, if a Contest allows each player to register with upto 3 teams, Player A can create and propose each of the 3 teams by inviting&nbsp; Additional Winning offers.</p>
                    <p class="">&nbsp; &nbsp; 3.6. Any tax/levies/duty etc. as applicable on material prizes including Additional Winnings, shall be paid by the Proposer of the TradedTeam. Based on TDS (tax deducted at source) Rules for any sort of game winning in India, 31.2% TDS should be deducted on any Additional Winning above Rs.10,000/- for a single Traded Team.</p>
                    <p class="">&nbsp; &nbsp; 4.&nbsp;<strong class="">GAME OF SKILL</strong></p>
                    <p class="">&nbsp; &nbsp; 4.1 . In providing the SKILLWINZ SuperTeam/MPL  game on the Platform, SKILLWINZ takes great care to comply with all central and state legislations in India to ensure that the Users are fully protected. SKILLWINZ SuperTeam/SKILLWINZ  is carefully designed to comply with all applicable statutes and regulations in India.</p>
                    <p class="">&nbsp; &nbsp; 4.2 . SKILLWINZ SuperTeam/MPL  offered on the Platform is a ‘game of skill’. According to the principles laid down in the judgement of the Punjab and Haryana High Court in&nbsp;<i class="">Varun Gumber</i> v.&nbsp;<i class="">Union Territory of Chandigarh</i> and of the Bombay High Court in&nbsp;<i class="">Gurdeep Singh Sachar</i> v.&nbsp;<i class="">Union of India and Others</i>, games such as SKILLWINZ SuperTeam/MPL  are games of ‘mere skill’ as the result is not dependent on winning or losing of any particular team in the real world, but depends on the performance of each player selected by a user which involves considerable skill, judgement and discretion and further, the success therein depended on one’s superior knowledge of the relevant sport, prudent judgment and attention.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 4.3 .By virtue of its classification as a game of skill,  sports do not violate the Public Gambling Act, 1867, and is excluded from the ambit of certain Indian gambling legislations. However, Users should note that owing to legislative restrictions in the Restricted States, and hence residents of such Restricted States are prohibited from participating in SKILLWINZ SuperTeam/MPL .</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">MPL Rummy</strong></p>
                    <p class="">&nbsp; &nbsp; In providing the SKILLWINZ Rummy game on the Platform, SKILLWINZ takes great care to comply with all central and state legislations in India to ensure that the Users are fully protected. SKILLWINZ Rummy is carefully designed to comply with all applicable statutes and regulations in India.</p>
                    <p class="">&nbsp; &nbsp; SKILLWINZ Rummy offered on the Platform is a ‘game of skill’. According to a 1967 Supreme Court judgment in&nbsp;<i class="">State of Andhra Pradesh v. K. Satyanarayana,&nbsp;</i>In the game of Rummy is a game of mere skill and playing Rummy does not amount to gambling.</p>
                    <p class="">&nbsp; &nbsp; Over the past few decades, various High Courts have quoted the apex court’s decision and stated that law enforcement authorities cannot interfere with the game of Rummy played with stakes. In 1996, a three-judge bench of the Supreme Court affirmed its 1967 judgment and ruled that games involving substantial and preponderant degree of skill fall within the definition of ‘games of mere skill’.</p>
                    <p class="">&nbsp; &nbsp; By virtue of its classification as a game of skill, the game of Rummy does not violate the Public Gambling Act, 1867, and is excluded from the ambit of most state gaming legislations. However, Users should note that owing to legislative/court restrictions in the Restricted States, hence residents of such Restricted States are prohibited from participating in SKILLWINZ Rummy.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">SPECIAL TERMS FOR SKILLWINZ RUMMY</strong></p>
                    <ol class="">
                        <li class="">&nbsp; &nbsp; Along with the Mobile Games developed by Third Party Game Developers, SKILLWINZ provides 13-card Rummy to its users (<strong class="">SKILLWINZ Rummy’</strong>). &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp;&nbsp;<strong class="">TERMS FOR SKILLWINZ RUMMY</strong> &nbsp; &nbsp;</li>
                    </ol>
                    <p class="">&nbsp; &nbsp; 2.1 . SKILLWINZ Rummy is a standard 13-card game of rummy offered to its users. The standard rules of the game apply to SKILLWINZ Rummy</p>
                    <p class="">&nbsp; &nbsp; 2.2 . Users can participate in a Multiplayer Contest or Tournament with other Users without any restriction on participation, unless specified otherwise in the Rules or the Agreement.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 2.3 .<strong class="">&nbsp;</strong>Collusion is strictly forbidden on SKILLWINZ Rummy. In case we detect that You and other users are playing games and tournaments forming a team it will result in cancellation of accounts of You and all such players who participated in forming a team. By accepting this Term, you accept that You will not participate in forming a team, or by communicating with other players. In case You are found to be involved in such activities, We also reserve the right to freeze funds in Your SKILLWINZ account and investigate further to ascertain the extent of undue benefit derived by You and such others and to cancel and close Your account without any communication with You<strong class="">.</strong></p>
                    <p class="">&nbsp; &nbsp; 2.4 . Should we at any time have reasonable ground to suspect that You have or are using fraudulent means which are in violation of the Terms of Use herein, that You have or are using multiple IDs which is in breach of the Terms of Use, We have the right to debar You, suspend Your account, prohibit Your access to the Website and withhold any prizes that You may have won, you hereby acknowledge and agree to the same.</p>
                    <p class="">&nbsp; &nbsp; 2.5 . Apart from the Restricted States of Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, Tamil Nadu and Telangana, SKILLWINZ Rummy is currently available to persons residing in other states. We reserve the right to update the Restricted States for SKILLWINZ Rummy as required by Applicable Law or court ruling.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Special terms for Beta Games</strong></p>
                    <p class="">&nbsp; &nbsp; From time to time, SKILLWINZ may provide games in “beta,” or any other in-development release form (such games hereinafter referred to as “Beta Games”).&nbsp;</p>
                    <p class="">&nbsp; &nbsp; By playing a Beta Game, you agree and acknowledge that:</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; any Beta Game is still in development, unfinished, and may have bugs, and that such Beta Game may not work as intended or at all; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; technical support may be offered by SKILLWINZ at its sole discretion, however, SKILLWINZ has no obligation to provide any support in connection with the Beta Game, nor any patches, updates, new releases, or new versions of the Beta Game.&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; SKILLWINZ may discontinue the Beta Game at any time in its sole discretion and may never make them generally available. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; ​​When playing the Beta Game, You may accumulate points, equipment, or other value or status indicators. This data may be reset at any time during the testing process, and it may be reset when the particular Beta Game build, patch or update completes the testing phase.&nbsp; &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Feedback</strong>: You may be asked to provide suggestions, comments, requests for noticeable bugs and other feedback with respect to the Beta Game (“Feedback”). You hereby grant SKILLWINZ a worldwide, exclusive, transferable, perpetual, irrevocable, royalty-free, fully paid-up right to:&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; make, have made, use, copy, modify, and create derivative works of the Feedback as part of any SKILLWINZ product, technology, service, specification or documentation;&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; publicly perform or display, import, broadcast, transmit, distribute, license, offer to sell and sell, rent, lease or lend copies of the Feedback (and derivative works thereof and improvements thereon); and&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; sublicense to third parties the foregoing rights, including the right to sublicense to further third parties. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Limitation of Liability.</strong> NEITHER SKILLWINZ, NOR SUBSIDIARIES OR AFFILIATES SHALL BE LIABLE IN ANY WAY FOR LOSS OR DAMAGE OF ANY KIND RESULTING FROM THE BETA GAME INCLUDING, BUT NOT LIMITED TO, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, SOFTWARE GLITCH OR MALFUNCTION, OR ANY AND ALL OTHER DAMAGES OR LOSSES. FURTHER, SKILLWINZ SHALL NOT BE LIABLE IN ANY WAY FOR THE LOSS OR DAMAGE TO PLAYER ACCOUNTS, STATISTICS OR USER PROFILE INFORMATION STORED BY THE BETA GAME. SKILLWINZ SHALL NOT BE RESPONSIBLE FOR ANY INTERRUPTIONS OF SERVICE INCLUDING, BUT NOT LIMITED TO ISP DISRUPTIONS, SOFTWARE FAILURES OR ANY OTHER EVENT WHICH MAY RESULT IN A LOSS OF DATA OR DISRUPTION OF SERVICE. IN NO EVENT WILL SKILLWINZ OR ITS SUBSIDIARIES OR AFFILIATES BE LIABLE TO YOU FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Special Terms for SKILLWINZ Audio Content</strong></p>
                    <p  class="">&nbsp; &nbsp;&nbsp;<strong class="">Annexure I</strong></p>
                    <p class="">&nbsp; &nbsp;  / SKILLWINZ provides a personalized pay-per-access service that allows our users / you to access certain audio, visual, audio-visual content including but not limited to podcasts, interviews, talk shows, chat shows, training videos, tutorials etc. (“<strong class="">Skillwinz  Content</strong>”) streamed over the Internet and accessible via the SKILLWINZ mobile application on your smart phones with necessary hardware and software requirements for accessing the SKILLWINZ Content (“<strong class="">Skillwinz  Content-ready device</strong>”).</p>
                    <p class="">&nbsp; &nbsp; These Terms of Use govern your use of our service in this regard. As used in these Terms of Use, "SKILLWINZ  Content service”, “our service” or “the service” means the personalized service provided by  for discovering and accessing SKILLWINZ Content, including all features and functionalities, recommendations and reviews, the mobile-application, and user interfaces, as well as all content and software associated with our service.</p>
                    <p class="">&nbsp; &nbsp; 1. Access</p>
                    <p class="">&nbsp; &nbsp; 1.1. Your access to the SKILLWINZ Content service is dependent upon payment of the designated fee (“access fee”) which shall be deducted from your Deposit Wallet.&nbsp; We may charge appropriate goods and service taxes on the access fee in addition to the access fee. In case your Deposit Wallet does not have sufficient balance to meet the access fee, you shall be prompted to top up your Deposit Wallet and once topped up, you will be given access to the SKILLWINZ Content on payment of the access fee. To use the SKILLWINZ Content service you must have Internet access and a SKILLWINZ Content-ready device.</p>
                    <p class="">&nbsp; &nbsp; 2. Free Trials</p>
                    <p class="">&nbsp; &nbsp; 2.1. We may offer some SKILLWINZ Content free of cost and such SKILLWINZ Content may be accessed on the SKILLWINZ Content-ready device without any deductions from your Deposit Wallet.</p>
                    <p class="">&nbsp; &nbsp; 3. Subscription</p>
                    <p class="">&nbsp; &nbsp; 3.1 We reserve the right to offer the SKILLWINZ Content on a subscription model or any alternate model of payment and access at our sole discretion.</p>
                    <p class="">&nbsp; &nbsp; 4. SKILLWINZ Content Service</p>
                    <p class="">&nbsp; &nbsp; 4.1. You must be 18 years of age, or the age of majority in your province, territory or country, to access the SKILLWINZ Content service. Minors may not access or use the SKILLWINZ Content service.</p>
                    <p class="">&nbsp; &nbsp; 4.2. The SKILLWINZ Content service and any content accessed through the service are for your personal and non-commercial use only and may not be shared with anyone. While accessing the SKILLWINZ Content after paying the relevant access fee we grant you a limited, non-exclusive, non-transferable right to access the SKILLWINZ Content service and access SKILLWINZ Content. Except for the foregoing, no right, title or interest shall be transferred to you. You agree not to use the service for broadcasting, public performances, sharing over any channels etc.</p>
                    <p class="">&nbsp; &nbsp; 4.3. You may access the SKILLWINZ Content only within India and we reserve the right to place any geographic restrictions on access to such content depending upon licensing conditions or having regard to local laws.&nbsp; The content that may be available for you to access will vary by geographic location and will change from time to time. Certain SKILLWINZ Content may be available for live streaming and shall not be available on the SKILLWINZ Content-ready device once the live-stream is over. Such content shall have to be accessed at designated streaming times according to the schedule available on the SKILLWINZ platform and we shall not be responsible for any loss in content warranted by a delay in accessing the said content.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 4.4. The SKILLWINZ Content service, including the content library, is regularly updated. In addition, we may continually test various aspects of our service, including our website, user interfaces, promotional features and availability of SKILLWINZ Content.</p>
                    <p class="">&nbsp; &nbsp; 4.5. Unless otherwise specified, payment of access fee for a particular SKILLWINZ Content entitles you to access it only once and any repeat access of the same content shall require you to pay the access fee again. We may at our sole discretion offer content that is available for temporary download and offline access on your SKILLWINZ Content-ready device (“<strong class="">Offline Content</strong>”). Such Offline Content once downloaded on to your SKILLWINZ Content-ready device may be accessed as many times as you wish without requiring payment of additional access fees. Limitations apply, including restrictions on the number of Offline Content per account, storage limitations of your SKILLWINZ account and / or your SKILLWINZ Content-ready device, the maximum number of SKILLWINZ Content-ready devices that can contain Offline Titles per account, the time period within which you will need to begin accessing Offline Titles and how long the Offline Titles will remain accessible. As SKILLWINZ Content is not available outside India, Offline Titles may also not be playable in countries outside India even though they were originally accessed, downloaded and stored while the user was in India.</p>
                    <p class="">&nbsp; &nbsp; 4.6. You agree to use the SKILLWINZ Content service, including all features and functionalities associated therewith, in accordance with all applicable laws, rules and regulations, or other restrictions on use of the service or content therein. You agree not to archive, reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for sale, or use (except as explicitly authorized in these Terms of Use) content and information contained on or obtained from or through the SKILLWINZ Content service. You also agree not to: circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in the SKILLWINZ Content service; use any robot, spider, scraper or other automated means to access the SKILLWINZ Content service; decompile, reverse engineer or disassemble any software or other products or processes accessible through the SKILLWINZ Content service; insert any code or product or manipulate the content of the SKILLWINZ Content service in any way; or use any data mining, data gathering or extraction method. In addition, you agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment associated with the SKILLWINZ Content service, including any software viruses or any other computer code, files or programs. We may terminate or restrict your use of our service if you violate these Terms of Use or are engaged in illegal or fraudulent use of the service.</p>
                    <p class="">&nbsp; &nbsp; 4.7. The quality of the audio or display of the SKILLWINZ Content may vary from device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/or speed of your Internet connection. Certain enhanced audio and video capabilities are subject to your Internet service and device capabilities. Not all content may be available on enhanced audio or video capabilities and default audio and video settings on cellular networks will exclude such enhanced capabilities. You are responsible for all Internet access charges. Please check with your Internet provider for information on possible Internet data usage charges. The time it takes to stream the SKILLWINZ Content once access to such content is granted will vary based on a number of factors, including your location, available bandwidth at the time, the content you have selected and the configuration of your SKILLWINZ Content-ready device.</p>
                    <p class="">&nbsp; &nbsp; 4.8. SKILLWINZ platform software is developed by, or for,  and is designed to enable use of SKILLWINZ services including SKILLWINZ Content services through SKILLWINZ Content-ready devices. This software may vary by device and medium, and functionalities and features may also differ between devices. You acknowledge that the use of the service may require third party software that is subject to third party licenses. You agree that you may automatically receive updated versions of the SKILLWINZ application and related third-party software.</p>
                    <p class="">&nbsp; &nbsp; 5. Passwords and Account Access. The user who created the SKILLWINZ account and is charged the access fee for the SKILLWINZ Content (the “Account Owner”) has access and control over the SKILLWINZ account and the SKILLWINZ Content-ready devices that are used to access our service and is responsible for any activity that occurs through the SKILLWINZ account. To maintain control over the account and to prevent anyone from accessing the account (which would include information on viewing history for the account), the Account Owner should maintain control over the SKILLWINZ Content-ready devices that are used to access the service and not reveal the password or details of the payment methods associated with the Deposit Wallet of the account to anyone.</p>
                    <p class="">&nbsp; &nbsp; 6. You are responsible for updating and maintaining the accuracy of the information you provide to us relating to your account. We can terminate your account or place your account on hold in order to protect you,  / SKILLWINZ or our partners from identity theft or other fraudulent activity.</p>
                    <p class="">&nbsp; &nbsp; 7. Warranties and Limitations on Liability. The SKILLWINZ Content service is provided “as is” and without warranty or condition. In particular, our service may not be uninterrupted or error-free. You waive all special, indirect and consequential damages against us. These terms will not limit any non-waivable warranties or consumer protection rights that you may be entitled to under the laws of your country of residence.</p>
                    <p class="">&nbsp; &nbsp; 8. Miscellaneous</p>
                    <p class="">&nbsp; &nbsp; 8.1. Governing Law. These Terms of Use shall be governed by and construed in accordance with the laws of India.</p>
                    <p class="">&nbsp; &nbsp; 8.2. Unsolicited Materials. We do not accept unsolicited materials or ideas for SKILLWINZ Content and we are not responsible for the similarity of any of its content or programming in any media to materials or ideas transmitted to us.</p>
                    <p class="">&nbsp; &nbsp; 8.3. Customer Support. To find more information about our service and its features or if you need assistance with your account, please visit the Help Center on our website. In the event of any conflict between these Terms of Use and information provided by Customer Support or other portions of our website, these Terms of Use will prevail.</p>
                    <p class="">&nbsp; &nbsp; 8.4. Survival. If any provision or provisions of these Terms of Use shall be held to be invalid, illegal, or unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in full force and effect.</p>
                    <p class="">&nbsp; &nbsp; 8.5. Changes to Terms of Use and Assignment. We may, from time to time, change these Terms of Use. We will notify you at least 30 days before such changes apply to you. We may assign or transfer our agreement with you including our associated rights and obligations at any time and you agree to cooperate with us in connection with such an assignment or transfer.</p>
                    <p class="">&nbsp; &nbsp; 8.6. Electronic Communications. We will send you information relating to your account (e.g. payment authorizations, invoices, changes in password or debits to Deposit Wallets, balance short-fall in payment of access fee, confirmation messages, notices) in electronic form only, for example via emails to your email address provided during registration.</p>
                    <p  class="">&nbsp; &nbsp;&nbsp;<strong class="">Terms for content providers (the Licensors)</strong></p>
                    <p  class="">&nbsp; &nbsp;&nbsp;<strong class="">Annexure II</strong></p>
                    <p  class="">&nbsp; &nbsp;&nbsp;<strong class="">Standard Content Licensing Agreement</strong></p>
                    <p class="">&nbsp; &nbsp; By hosting content on SKILLWINZ audio shows you (the “Licensor”) agree to be bound by the terms of this Standard Content Licensing Agreement.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">WHEREAS</strong></p>
                    <p class="">&nbsp; &nbsp; 1. The Licensor, owns the underlying intellectual property including the copyright in the content more specifically described in&nbsp;<strong class="">Schedule I</strong> (the “<strong class="">Content</strong>”).</p>
                    <p class="">&nbsp; &nbsp; 2. The Licensee, a company incorporated under the laws of India operates a mobile application and associated website which offers games and audio-visual / video / audio content through online streaming on its platform (the “<strong class="">Skillwinz </strong>”).</p>
                    <p class="">&nbsp; &nbsp; 3. The Licensor agrees to license the right to use the Content to the Licensee and all underlying intellectual property in such Content including copyrights if any in accordance with the terms and conditions set forth herein and the Licensee agrees to accept the license on the terms and conditions set forth herein.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">NOW THEREFORE</strong>, the parties, upon negotiations, agree as follows:</p>
                    <p class="">&nbsp; &nbsp; 1. Granting of License</p>
                    <ol class="">
                        <li class="">&nbsp; &nbsp; The Content &nbsp; &nbsp;</li>
                    </ol>
                    <p class="">&nbsp; &nbsp; 1.1.1 Upon the terms and conditions hereinafter set forth, the Licensor hereby grants to the Licensee and the Licensee hereby accepts the right to use the Content in India.</p>
                    <p class="">&nbsp; &nbsp; 1.1.2 The Licensor shall have the sole and exclusive ownership of the Content, including all improvements, updates, derivative products and intellectual property rights thereof, whether such improvements, updates, derivative products and intellectual property rights are made by the Licensor or the Licensee. The rights and obligations under this paragraph shall survive the termination of this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 1.2 Scope</p>
                    <p class="">&nbsp; &nbsp; 1.2.1 The right to use the Content granted by the Licensor to the Licensee is effective only for the business operation of the SKILLWINZ platform by the Licensee. The Licensor agrees that the Licensee may use the Content on any other website or media pursuant to the purpose of the license and to enable the Licensee to fully utilize the underlying intellectual properties granted in respect of the Content as far as such use relates to the SKILLWINZ platform.</p>
                    <p class="">&nbsp; &nbsp; 1.2.2 The right to use the Content granted by the Licensor to the Licensee is effective only in India.</p>
                    <p class="">&nbsp; &nbsp; 1.2.3 The license provided by the Licensor is exclusive to the Licensee in India and the Licensor shall not license the Content to any third-party during the term of this Agreement without the consent of the Licensee irrespective of the scope of this license.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 1.2.4 The Licensor acknowledges and agrees that the Licensor shall provide the Content for access, viewing, listening etc. on the SKILLWINZ platform to its users and may permit its users to download the said Content for offline access, viewing, listening etc. on the SKILLWINZ platform (“<strong class="">Access</strong>”).</p>
                    <p class="">&nbsp; &nbsp; 2. Terms of Payment</p>
                    <p class="">&nbsp; &nbsp; The Licensee agrees to pay the Licensor license fees amounting to INR … per unique Access by the Licensee’s users on the SKILLWINZ platform.&nbsp; For avoidance of doubts, it is clarified that; if the licensed Content is accessed multiple times from the same device or multiple devices associated with the same user account, the Licensor and the Licensee shall treat the same as a single (1) unique Access and license fees shall be payable only once in respect of such Access.</p>
                    <p class="">&nbsp; &nbsp; The Licensee shall pay the license fees for each audio show as conducted by the licensor after providing the Licensor with details of the number of unique Access during such period in the manner detailed out in&nbsp;<strong class="">Schedule II</strong>.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; The Licensor shall have to raise a tax invoice on the Licensee to enable the Licensee to pay the license fee.</p>
                    <p class="">&nbsp; &nbsp; The Licensor acknowledges that the Licensee may deduct appropriate income taxes at source as per applicable laws.</p>
                    <p class="">&nbsp; &nbsp; Any and all indirect taxes including goods and service taxes shall be borne by the Licensor and the same shall be accounted for in the tax invoice raised by the Licensor on the Licensee.</p>
                    <p class="">&nbsp; &nbsp; 3. Intellectual Property and Confidentiality</p>
                    <p class="">&nbsp; &nbsp; 3.1 The Licensee shall use its reasonable effort to protect and maintain the confidentiality of any and all data and information from the Licensor marked as or known by the Licensee to be confidential (collectively, the “Confidential Information”). Upon termination of this Agreement, the Licensee shall return any Confidential Information to the Licensor or destroy it itself, delete any Confidential Information from any electronic devices and cease to use such Confidential Information as required by the Licensor. The Licensee shall not disclose, grant or transfer any Confidential Information to any third party without the Licensor’s written consent.</p>
                    <p class="">&nbsp; &nbsp; 3.2 Both parties agree that this Clause 3 shall survive the invalidity, amendment, cancellation, termination or unenforceability of this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 4. Representations and Warranties</p>
                    <p class="">&nbsp; &nbsp; 4.1 The Licensor represents and warrants as follows:</p>
                    <p class="">&nbsp; &nbsp; 4.1.1 It has the exclusive ownership of the Content including all underlying intellectual property therein and is not bound by any obligations under law or under contract which prevent it from entering into this Agreement or licensing the Content to the Licensee having regard to the scope of the license.</p>
                    <p class="">&nbsp; &nbsp; 4.1.2 The Content does not contain any material that is in any way illegal, offensive, against public morality or order, or may hurt the sentiments of any group of people, or is disparaging or defamatory of any person or groups of persons.</p>
                    <p class="">&nbsp; &nbsp; 4.1.3 The execution and performance of this Agreement by it are within its power and it has taken all necessary actions and obtained all necessary consents or approvals by third parties or government agencies to enter into this Agreement and perform this Agreement. The execution and performance of this Agreement by it do not violate the laws and contracts binding upon or influencing it; and</p>
                    <p class="">&nbsp; &nbsp; 4.1.4 Upon execution, this Agreement will constitute a legal, valid and binding obligation of the Licensor enforceable against the Licensor in accordance with its terms.</p>
                    <p class="">&nbsp; &nbsp; 4.2 The Licensee represents and warrants as follows:</p>
                    <p class="">&nbsp; &nbsp; 4.2.1 It is a company duly incorporated in India and validly existing under the laws of India;</p>
                    <p class="">&nbsp; &nbsp; 4.2.2 The execution and performance of this Agreement by it are within its corporate power and business scope. It has taken all necessary actions and obtained all necessary consents or approvals by third parties or government agencies. The execution and performance of this Agreement by it do not violate the laws and contracts binding upon or influencing it; and</p>
                    <p class="">&nbsp; &nbsp; 4.2.3 Upon execution, this Agreement will constitute a legal, valid and binding obligation of the Licensee enforceable against the Licensee in accordance with its terms.</p>
                    <p class="">&nbsp; &nbsp; 5. Licensor’s Ownership and Protection of Licensor’s Rights</p>
                    <p class="">&nbsp; &nbsp; 5.1 The Licensee agrees, during the term of this Agreement and thereafter, it shall not challenge the ownership and other rights of the Licensor in the Content.</p>
                    <p class="">&nbsp; &nbsp; 5.2 The Licensee agrees to provide necessary assistance to the Licensor to protect the Licensor’s rights with respect to the Content and inform the Licensee of any infringement by any third party in writing if it becomes aware of or has knowledge of such infringement.</p>
                    <p class="">&nbsp; &nbsp; 5.3 The Licensee agrees that it shall use the Copyright only in accordance with this Agreement and shall not to use the Copyright in any manner that could be deemed by the Licensor to be fraudulent, misleading or otherwise harmful to the Copyright or the reputation of the Licensor.</p>
                    <p class="">&nbsp; &nbsp; 6. Indemnity</p>
                    <p class="">&nbsp; &nbsp; 6.1 The Licensor hereby indemnifies the Licensee against any and all third-party claims without limitation arising out of breach of any of the representations and warranties of the Licensor pursuant to clause 4.</p>
                    <p class="">&nbsp; &nbsp; 6.2 The Licensee shall have the right to withhold any sums due to the Licensee including the license fees to meet any liabilities including legal costs incurred or likely to be incurred by the Licensee arising out of a claim by any third party resulting from a breach of the representations and warranties of the Licensor pursuant to clause 4.</p>
                    <p class="">&nbsp; &nbsp; 7. Effective Date and Term</p>
                    <p class="">&nbsp; &nbsp; 7.1 This Agreement shall be executed and become effective as of the date first set forth above. The term of this Agreement is one (1) year unless terminated earlier pursuant to this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 7.2 This Agreement may be extended automatically for one year upon its expiration (including the expiration of any extended term) unless the Licensor prior to the expiration hereof gives written notice not to extend this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 8. Termination</p>
                    <p class="">&nbsp; &nbsp; 8.1 This Agreement shall terminate on the date of expiration or the date of the expiration of extended terms when the Licensor notifies the Licensee in writing not to extend this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 8.2 Without prejudice to any legal or other rights or remedies of the party who asks for termination of this Agreement, any party has the right to terminate this Agreement immediately with written notice to the other party in the event the other party materially breaches this Agreement including but not limited to Clause 3, 4 or 5 of this Agreement and fails to cure such breach within 30 days from the date the breaching party receives the written notice of its breach from the non-breaching party.</p>
                    <p class="">&nbsp; &nbsp; 8.3 During the term of this Agreement, the Licensor as well as the Licensee may terminate this Agreement at any time by providing thirty (30) days’ written notice to the other party.</p>
                    <p class="">&nbsp; &nbsp; 8.4 Clauses 1.1.2, 3, 5, 6 and 11 shall survive the termination or expiration of this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 9. Effect of Termination or Expiration</p>
                    <p class="">&nbsp; &nbsp; Upon and after the expiration or termination of this Agreement, all rights granted to the Licensee hereunder shall forthwith revert to the Licensor, which shall be free to license the right to use the Content to others and the Licensee cease any further direct or indirect use of the Content.</p>
                    <p class="">&nbsp; &nbsp; 10. Force Majeure</p>
                    <p class="">&nbsp; &nbsp; 10.1 Force Majeure, which includes but not limited to acts of governments, acts of nature, fires, explosions, typhoons, floods, earthquakes, tides, lightning or war, means any unforeseen event that is beyond the party’s reasonable control and cannot be prevented with reasonable care of the affected party. However, any insufficiency of creditworthiness, capital or financing shall not be regarded as an event beyond the party’s reasonable control. The party affected by Force Majeure and seeking exemption from performing the obligations under this Agreement shall inform the other party of such exemption and any action taken by it in performing this Agreement.</p>
                    <p class="">&nbsp; &nbsp; 10.2 In the event that the affected party is delayed in or prevented from performing its obligations under this Agreement by Force Majeure, and only to the extent such delay and prevention, the affected party shall not be liable for obligations under this Agreement. The affected party shall take appropriate measures to minimize or remove the effects of Force Majeure and attempt to resume the performance of the obligations that were delayed or prevented by the event of Force Majeure. Once the event of Force Majeure is removed, both parties agree to resume the performance of this Agreement using their best efforts.</p>
                    <p class="">&nbsp; &nbsp; 11. Settlement of Disputes</p>
                    <p class="">&nbsp; &nbsp; Any dispute arising in connection with the interpretation and performance of the provisions of this Agreement shall be resolved by the parties in good faith through negotiations. In case no resolution can be reached by the Parties within thirty (30) days after either party makes a request for a dispute resolution through negotiations, either party may refer such dispute to adjudication through arbitration under the applicable laws. The dispute shall be referred to a sole arbitrator appointed in accordance with applicable laws.</p>
                    <p class="">&nbsp; &nbsp; 12. Notices</p>
                    <p class="">&nbsp; &nbsp; Notices or other communications required to be given by any party pursuant to this Agreement shall be emailed to SKILLWINZ at&nbsp;<strong class="">legal@skillwinzgaming.com</strong> and to the Licensor by the email as provided to the SKILLWINZ</p>
                    <p class="">&nbsp; &nbsp; 13. Assignment and Sublicense</p>
                    <p class="">&nbsp; &nbsp; 13.1 The rights and obligations licensed by the Licensor to the Licensee pursuant to this Agreement shall not be assigned, pledged or sublicensed without the prior written consent of the Licensor.</p>
                    <p class="">&nbsp; &nbsp; 13.2 The Licensor hereby agrees that it shall not transfer the rights and obligations under this Agreement to any third party without the prior written consent of the Licensee.</p>
                    <p class="">&nbsp; &nbsp; 14. Applicable Law</p>
                    <p class="">&nbsp; &nbsp; The validity, performance and interpretation of this Agreement shall be governed by the laws of India.</p>
                    <p class="">&nbsp; &nbsp; 15. Amendment or Supplement</p>
                    <p class="">&nbsp; &nbsp; SKILLWINZ may amend or supplement this Agreement in accordance with Clause 1.6 above.</p>
                    <p class="">&nbsp; &nbsp; 16. Severability</p>
                    <p class="">&nbsp; &nbsp; If any provision of this Agreement is judged to be invalid or unenforceable because it is inconsistent with applicable laws, such invalidity or unenforceability shall be only with respect to such laws, and the validity, legality and enforceability of the other provisions hereof shall not be affected.</p>
                    <p class="">&nbsp; &nbsp; 17. Appendices and Schedules</p>
                    <p class="">&nbsp; &nbsp; The Appendices and Schedules to in this Agreement shall form an integral part of this Agreement and shall have the same legal effect as this Agreement.</p>
                    <p  class="">&nbsp; &nbsp;&nbsp;<strong class="">Schedule I</strong></p>
                    <p class="">&nbsp; &nbsp; Description of Content</p>
                    <p class="">&nbsp; &nbsp; Content as provided on SKILLWINZ audio shows from time to time.</p>
                    <p  class="">&nbsp; &nbsp;&nbsp;<strong class="">Schedule II</strong></p>
                    <p class="">&nbsp; &nbsp; Payment Mechanism</p>
                    <p class="">&nbsp; &nbsp; The Licensor will be shown the number of unique access received by the licensor for an audio show as well as the commensurate license fee payable to the licensor. The same will be credited to the Linked Wallet Details of Licensor on SKILLWINZ platform</p>
                    <p class="">&nbsp; &nbsp; Frequency of Payments… per audio show</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">DISCLAIMER</strong>: Unless otherwise explicitly specified, SKILLWINZ is not affiliated in any way to and claims no association, in any capacity whatsoever, with any sports governing bodies and leagues, including, but not limited to the Board of Control for Cricket in India (BCCI) or the Indian Premier League (IPL).</p>
                    <h4 class="">&nbsp; &nbsp;&nbsp;<strong class="">Anti-Money Laundering Policy</strong></h4>
                    <p class="">&nbsp; &nbsp; This Anti-Money Laundering Policy (“<strong class="">AML Policy</strong>”) is testimony to the Company’s commitments against money laundering, financing of terrorism, and related illegal activities. It describes the Company’s policies and procedures instituted in an attempt to ensure that the Services offered by the Company are not being used by the Users to facilitate commission of any criminal offences or violating any Applicable Laws, including but not limited the Prevention of Money Laundering Act 2002 and the Unlawful Activities Prevention Act 1967. Although under the said laws, the Company does not qualify as an entity obligated to follow the procedures prescribed herein, the Company has prepared this AML Policy to ensure transparency in the activities conducted from the Account and to prevent money laundering and other illegal activities over the Platform in consonance with the spirit of law.&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;
                        <br class=""/>&nbsp; &nbsp;&nbsp;&nbsp;
                        <br class=""/>&nbsp; &nbsp; &nbsp;</p>
                    <p class="">&nbsp; &nbsp; This AML Policy applies uniformly to any User desirous of availing the Services or otherwise using or benefitting from the use of the Platform and should be read as a part of the Terms of Use. It is imperative that You read this AML Policy before using the Platform or submitting any personal information. By using the Platform, You are expressly consenting to be bound by the Terms of Use and consequently this AML Policy.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">Definitions</strong></p>
                    <p class="">&nbsp; &nbsp; 1.1 . “<strong class="">Beneficial Owner</strong>” means: In case of companies, the natural person who has ownership of over 25% (twenty five per cent) of the shares; is entitled to over 25% (twenty five percent) of the profits; or has the power, directly or indirectly, to appoint or elect more than half of the board of directors of such company, as the case maybe. In case of partnership firms/limited liability partnerships, the natural person who has ownership of over 15% (fifteen per cent) of the capital; or is entitled to over 15% (fifteen per cent) of the profits of such firm, as the case maybe;</p>
                    <p class="">&nbsp; &nbsp; 1.2 . “<strong class="">Identification Document(s)</strong>” refers to: (i) Permanent Account Number (PAN) card; (ii) Passport; (iii) Driving License; (iv) Government issued identity cards; or (v) such other document as may be notified by the Company from time to time;</p>
                    <p class="">&nbsp; &nbsp; “<strong class="">Regulated Entity</strong>” shall mean banks, financial institutions or intermediaries who are mandated by law to maintain records under the provisions of the Prevention of Money-laundering (Maintenance of Records of the Nature and Value of Transactions, the Procedure and Manner of Maintaining and Time for Furnishing Information and Verification and Maintenance of Records of the Identity of the Clients of the Banking Companies, Financial Institutions and Intermediaries) Rules, 2005 (“<strong class="">Verification Rules</strong>”).</p>
                    <p class="">&nbsp; &nbsp; 1.3 .“<strong class="">Suspicious Transactions</strong>” refers to the following activities, whether attempted or executed:&nbsp;</p>
                    <p class="">&nbsp; &nbsp; (i)&nbsp;<i class=""><strong class="">T</strong>errorist financing</i>: transactions which to a Person acting in good faith appear to have been done in order to collect funds, in full or in part, by any terrorist or related organization, or in order to carry out any of the activities relating to terrorism, or terrorist acts;</p>
                    <p class="">&nbsp; &nbsp; (ii)&nbsp;<i class="">Unusually Complex</i>: transactions which to a Person acting in good faith appear to have been structured in a manner of unusual or unjustified complexity;</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class="">(iii) Mala-fide Purpose</i>:&nbsp; transactions which to a Person acting in good faith appear to have not been transacted for bona-fide purpose or do not have a sound economic rationale.</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<i class="">(iv) Money Laundering</i>: transactions which to a Person acting in good faith appear to involve proceeds of any offence listed in the Schedule to the Prevention of Money Laundering Act, 2002.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 1.4 . The capitalized terms used herein, but not defined, shall have the meaning given to our terms of use available at(“<strong class="">Terms of Use</strong>”).</p>
                    <p class="">&nbsp; &nbsp; 2.&nbsp;<strong class="">AML Policy Is Part Of Our Terms –&nbsp;</strong>This AML Policy is a part of and incorporated within, and is to be read along with the Terms of Use (the “<strong class="">Terms of Use</strong>”).</p>
                    <p class="">&nbsp; &nbsp; 3.&nbsp;<strong class="">Policy Changes –&nbsp;</strong>The Company may change and update this AML Policy from time to time. Such changes may be made without prior notice, but any changes will only apply to activities and information going forward, and not on a retroactive basis. You are encouraged to review this AML Policy whenever you access the Platform.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 4.&nbsp;<strong class="">Your Obligations</strong></p>
                    <p class="">&nbsp; &nbsp; 4.1 . You acknowledge that it is Your duty to ensure compliance with the terms and conditions described in this AML Policy and accord Your consent to not using the Services and the Platform in any manner which results in committing/attempting to commit any criminal offences.</p>
                    <p class="">&nbsp; &nbsp; 4.2 . You must ensure that any personal information and/or Identification Documents submitted by You belong to You.</p>
                    <p class="">&nbsp; &nbsp; 4.3 . You must update any information provided by You basis your Identification Documents within 30 (thirty) days of any change or modification thereof.</p>
                    <p class="">&nbsp; &nbsp; 4.4 . In case You are acting on behalf of a juridical person, You must identify the Beneficial Owner and also assist in verification of the identity of such Beneficial Owner and any individual who purports to act on behalf of such juridical person.</p>
                    <p class="">&nbsp; &nbsp; 5.&nbsp;<strong class="">Purpose of this Policy –&nbsp;</strong>In order to mitigate its risks relating to money laundering and other illegal activities, the Company intends to put in place this policy which has the following elements:&nbsp;</p>
                    <p class="">&nbsp; &nbsp; (i) Customer Acceptance and Verification Terms;</p>
                    <p class="">&nbsp; &nbsp; (ii) Risk Management Procedure; and</p>
                    <p class="">&nbsp; &nbsp; (iii) Transaction Monitoring Terms</p>
                    <p class="">&nbsp; &nbsp; 6.&nbsp;<strong class="">Customer Acceptance and Verification Terms</strong></p>
                    <p class="">&nbsp; &nbsp; 6.1 . The SKILLWINZ Platform may only be used by Persons who fulfill our eligibility criteria as laid down in our Terms of Use. Users may further partake any Services offered by this Platform only after registering themselves on the Platform in accordance with the procedure stated in our Terms of Use.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 6.2 . Money may be deposited or withdrawn from the concerned SKILLWINZ Account by a User only by means of an account maintained with a Regulated Entity. It is clarified that only those Users whose accounts with the Regulated Entity have been verified by the Regulated Entities in accordance with the Verifications Rules may deposit or withdraw money from their SKILLWINZ Account.</p>
                    <p class="">&nbsp; &nbsp; 6.3 . Users are further required to undergo a KYC verification process as well as account validation process whenever they seek to withdraw Winning from their Account as provided in the Terms of Use. For the purposes of such KYC verification of any User’s identity, the Company will rely on appropriate third-party service providers to authenticate the Identification Documents and other incidental details provided by the User. Users must ensure that they have adhered to the KYC requirements applicable to them with such third-party service providers (including payment partners) on the Platform.</p>
                    <p class="">&nbsp; &nbsp; 6.4 . Notwithstanding the foregoing, SKILLWINZ reserves the right to seek further information from the Users, including Identification Documents.</p>
                    <p class="">&nbsp; &nbsp; 6.5 . The Company may, in its sole discretion, refuse to open any new Account, terminate existing Account after giving due notice, or refuse to process any transactions on the Platforms if it is unable to ensure Your compliance with this AML Policy, either due to non-cooperation by the User or due to the details provided by the User being found unreliable or unverifiable to the Company’s satisfaction.</p>
                    <p class="">&nbsp; &nbsp; 7.&nbsp;<strong class="">Risk Management Procedure&nbsp;</strong></p>
                    <p class="">&nbsp; &nbsp; 7.1 . The Company may categorize its Users including You into low, medium or high-risk categories, after undertaking an appropriate risk assessment of each User based on the following factors (including without limitation):</p>
                    <p class="">&nbsp; &nbsp; (i)Sufficiency and adequacy of identification information submitted by the User;</p>
                    <p class="">&nbsp; &nbsp; (ii) User’s social and/or financial status;</p>
                    <p class="">&nbsp; &nbsp; (iii) Nature of User’s business/vocational activities; or</p>
                    <p class="">&nbsp; &nbsp; (iv) Guidance notes circulated by various governmental and inter-governmental organizations.</p>
                    <p class="">&nbsp; &nbsp; 7.2 . You acknowledge that in order to maintain the integrity of the risk management procedure, the Company will keep Your risk categorization and any data related thereto confidential. You will not be entitled to seek disclosure of Your risk categorization. However, the Company may disclose the User’s risk categorization data to the competent enforcement authority if it finds that a particular User has executed or is likely to execute any Suspicious Transaction.</p>
                    <p class="">&nbsp; &nbsp; 8 .&nbsp;<strong class="">Transaction Monitoring Terms</strong></p>
                    <p class="">&nbsp; &nbsp; 8.1 . All transactions executed and/or attempted to be executed from the Account are regularly monitored by the Company, both manually and through use of software-based algorithms, in order to promptly identify and highlight certain kinds of transaction including without limitation, the following kinds of transactions:</p>
                    <p class="">&nbsp; &nbsp; (i) High value transactions of such amounts as may be specified by SKILLWINZ from time to time or as may be required under Applicable Law;</p>
                    <p class="">&nbsp; &nbsp; (ii) Low value but repetitive transaction of such amounts as may be specified by SKILLWINZ from time to time or as may be required under Applicable Law; and</p>
                    <p class="">&nbsp; &nbsp; (iii) Suspicious Transactions.</p>
                    <p class="">&nbsp; &nbsp; 8.2 . The Company may, from time to time, undertake necessary investigation in order to identify and examine transactions inconsistent with any User’s risk profile (determined in accordance with Clause 7 (<i class="">Risk Management Procedure</i>) above), sophistication, and expected usage pattern.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 8.3 . The extent of monitoring shall depend on various factors including upon each User’s risk profile.</p>
                    <p class="">&nbsp; &nbsp; 8.4 . The Company may, from time to time, undertake necessary investigation in order to identify and examine transactions inconsistent with any User’s risk profile (determined in accordance with Clause 7 (<i class="">Risk Management Procedure</i>) above), sophistication, and expected usage pattern.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 9.&nbsp;<strong class="">Maintenance of Records – The Company will maintain and preserve the following:&nbsp;</strong></p>
                    <p class="">&nbsp; &nbsp; (i) Records of all transactions executed on the Platform, for a period of at least 3 (three) years from the date of each transaction.</p>
                    <p class="">&nbsp; &nbsp; (ii) Records of all transactions identified under Clause 8 (<i class="">Transaction Monitoring Terms</i>) above for a period of at least 3 (three) years, including but not limited to the information about the nature, value and parties to such transactions, and their date of remittance.</p>
                    <p class="">&nbsp; &nbsp; (iii) Identification records of Users during the subsistence of and for a period of at least 3 (three) years from the date of termination of such Account.</p>
                    <p class="">&nbsp; &nbsp; (iv) Records of any communication or interaction between with SKILLWINZ (and its employees, agents, staff or affiliates) with any third party in official capacity or having/intending to have business or commercial relationships with SKILLWINZ directly or indirectly , in any form whether oral or written, for all purposes as deemed fit by SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; 10 .&nbsp;<strong class="">Compliance, Disclosure, and Notices</strong></p>
                    <p class="">&nbsp; &nbsp; 10.1 . The Company may share, from time to time, information regarding transactions identified under Clause 8 (<i class="">Transaction Monitoring Terms</i>), identification information of such Users, or any other information mandated under the Applicable Law, with the appropriate enforcement authorities.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; 10.2 . In order to improve the integrity and transparency of transactions on the Platform, You are encouraged to report any information You are privy to or become privy to in the future regarding any Suspicious Transactions or transactions You have find or have reason to believe are dubious in nature, to our Grievance Officer by writing to them at legal@skillwinzgaming.com</p>
                    <p class="">&nbsp; &nbsp; 10.3 .&nbsp;<strong class="">In order to ensure compliance with this AML Policy and/or the Applicable Law, the Company may be required to send You notices from time to time. All such notices will be sent to such address as provided by You under Clause 6 (</strong><i class=""><strong class="">Customer Acceptance and Verification Terms</strong></i><strong class="">) of this AML Policy. Where You are required to share any information according to the procedures contained in this AML Policy, such communication may be made by You electronically by sending an email to legal@skillwinzgaming.com.</strong></p>
                    <p class="">&nbsp; &nbsp; 10.4 . You may also contact us at the following address: Sakti Statesman, 6th Floor, green Glen Layout, Bellandur, Jaipur- 560103</p>
                    <p  class="">&nbsp; &nbsp;&nbsp;<strong class="">Annexure II</strong>I</p>
                    <p class="">&nbsp; &nbsp;&nbsp;<strong class="">COMMUNITY GUIDELINES</strong></p>
                    <p class="">&nbsp; &nbsp; We at Mobile Premier League (MPL), as a platform, constantly strive to ensure that we are a safe and authentic medium to explore various skill, learning based digital games and range of content in the form of audio, video, text and/or image media etc.&nbsp; We are an ethics driven community and we are committed to create a safe and open environment for everyone.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; The guidelines set out below provide a general guidance and overview of what is allowed and what is not allowed on our platform (<strong class="">Community Guidelines</strong>), and applies to anything hosted, commented, communicated on our platform (hereinafter, referred to as&nbsp;<strong class="">content</strong>). We have created these Community Guidelines in order to ensure that you help us to grow and protect the values that form the foundation of our community at SKILLWINZ. By using the SKILLWINZ app, you agree to these Community Guidelines and our&nbsp;<a href="http://skillwinz.com/termsconditions/" class=""><span  class="">‘Terms of Use</span></a>’. Our Community Guidelines reflect our principles and a common code of conduct for our platform. These Community Guidelines apply to all users with an account on the SKILLWINZ app or who are hosted on the SKILLWINZ app and to all content shared on SKILLWINZ app.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; We ensure that any violation of these Community Guidelines, either reported to us, or noted by us, shall be taken very seriously, reviewed by an internal team at SKILLWINZ and appropriate actions shall be taken and may result into deleting such content, disabling/deleting of accounts, and other restrictions as deemed appropriate, or may even be reported to legal authorities if required.&nbsp; We also recognize that some content that would normally be removed per our Community Guidelines may be valuable to public and we do recognize certain exceptions under specific circumstances, as described in the sections below.&nbsp; We follow a transparent and fair process to review content on the SKILLWINZ App to ensure that at no point of time there is any violation of Community Guidelines.&nbsp; Any action or decision taken by SKILLWINZ for violation of the Community Guidelines shall be at the sole discretion of SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; At the outset, please note that any content including any communication / interaction of the following nature is prohibited and may warrant action from SKILLWINZ.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; Details of each of the aforementioned items have been explained and set out below.&nbsp; Please refer to the details set out in each such paragraph relating to specific acts and read the same carefully in order to understand what does each such prohibition entail.</p>
                    <p class="">&nbsp; &nbsp; Please note that we have tried to draft our Community Guidelines as detailed as possible, however this is not an exhaustive list of policies and every content, if reported, shall be analysed based on the parameters mentioned in these guidelines that may be indirectly or directly violating these guidelines. User and host discretion are warranted to ensure that apart from the examples listed below, no content shall be posted that may violate these guidelines.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; We update our Community Guidelines from time to time to ensure that we act as a safe platform.</p>
                    <p class="">&nbsp; &nbsp; We urge you that if you find content that violates this Community Guidelines, please report it.</p>
                    <p class="">&nbsp; &nbsp; I. NUDITY AND SEXUAL CONTENT POLICY</p>
                    <p class="">&nbsp; &nbsp; Sexually explicit or gratifying content (including pornography) is prohibited on the SKILLWINZ app, including any animated content of such nature. Such content is illegal in certain jurisdictions and may warrant sharing of non-consensual images or may be hurtful to cultural sentiments.&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Genitals, breasts, female nipples, or buttocks; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Pornography such as sexual acts, genitals, or fetishes including paedophilic content; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Masturbation or fondling of genitals, breasts, or buttocks; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Using or displaying sex toys; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Wardrobe accidents or nude photos; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Violent, graphic, or humiliating sexual fetish content; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Nudity or partial nudity; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Explicit or implied depiction of sex acts; sexual arousal; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Animated sex acts, pornography, or fetish content; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Commission or incitement of Non-consensual sex acts or unwanted sexualization; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Bestiality or promotion of bestiality; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Incest or promotion of incest; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Any sexual content involving minors; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Commission, promotion, or glorification of sexual solicitation or sexual objectification; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Sexual activities such as penetration, non-penetrative sex, or oral sex; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Clips extracted from non-pornographic films, shows, or other content in order to isolate sexual content; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Groping, public masturbation, voyeurism, predatory exhibitionism or any other content that depicts someone in a sexualized manner without their consent; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Provocative dancing that is focused on the dancer’s genitals, buttocks, nipples, or breasts; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that sexualizes rape in any form, or content that aggregates clips of dramatized rape scenes; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content using otherwise everyday objects or scenarios, such as injections or eating, for the purpose of sexual gratification; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Video game content which has been developed or modified for sexual gratification, or focuses on themes of unwanted sexualization. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; Exceptions: Restrictions on the display of sexual activity may be relaxed only by SKILLWINZ specifically for content posted for educational purposes.</p>
                    <p class="">&nbsp; &nbsp; II. MINOR SAFETY</p>
                    <p class="">&nbsp; &nbsp; Users and hosts on SKILLWINZ app are required to meet minimum age requirements for use of the SKILLWINZ app (as stipulated in our Terms of Use). We have a zero tolerance for predatory behaviour towards minors. We do not allow content that depicts or disseminates child abuse, child nudity, or sexual exploitation of children.&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; The private parts of minors; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Sexual exploitation of minors; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Sexual activities such as penetration, non-penetrative sex, or oral sex involving minors; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Sexual arousal involving minors; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Sexual fetish involving minors; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Minors consuming, possessing, or suspected of consuming alcoholic beverages, drugs, or tobacco; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Physical or psychological abuse involving minors i.e., deliberately inflicting injury on a minor’s body or berating a child with threats of either physical or sexual violence or&nbsp;<i class="">via</i> bully tactics; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Engagement of a minor in a sexually explicit conversation; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Incentivization or blackmailing a child to transmit sexually explicit material; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Encouragement, instruction, or justification of grooming behaviour wherein an adult builds an emotional relationship with a minor to gain their trust for the purposes of sexual abuse, sexual exploitation, or sexual trafficking; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Erotic dances involving minors;&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that contains sexual or erotic language involving minors; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Vulgar language not appropriate for younger audiences or use of sexually explicit language or excessive profanity. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; III. INTELLECTUAL PROPERTY</p>
                    <p class="">&nbsp; &nbsp; We as a platform encourage everyone to create and share original content that does not infringe any intellectual property rights of anyone else.&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Content that violates or infringes someone else’s copyrights, trademarks, or other intellectual property rights; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Plagiarism of other content; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Plagiarism of other user profiles by copying profile elements of other user. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; IV. HARMFUL OR DANGEROUS CONTENT POLICY</p>
                    <p class="">&nbsp; &nbsp; We do not allow content that encourages dangerous or illegal activities that risk serious physical harm or death.</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Challenges that pose an imminent risk of physical injury or emotional abuse; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Pranks that lead victims to fear imminent serious physical danger, or that create serious emotional distress; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Instructing or showing viewers how to perform activities meant to kill or maim others; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Abuse of or giving instructions on how to create hard drugs such as cocaine or opioids. Hard drugs are defined as drugs that can (mostly) lead to physical addiction; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Eating disorders, praising, glorifying, or encouraging viewers to imitate anorexia or other eating disorders such as abnormal or disturbed eating habits which negatively affect a person’s health (including eating non-food items); &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Promoting or glorifying violent tragedies; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Showing viewers how to steal money or tangible goods; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Demonstrating how to use computers or information technology with the intent to steal credentials, compromise personal data or cause serious harm to others such as (but not limited to) hacking into social media accounts; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content which claims that harmful substances or treatments can have health benefits. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; Exception – We might allow videos that depict dangerous acts if they’re meant to be educational, documentary, scientific, or artistic, which shall be the sole discretion of SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; V. VIOLENT AND GRAPHIC CONTENT</p>
                    <p class="">&nbsp; &nbsp; We do not allow content that is excessively gruesome or shocking, especially that promotes or glorifies violence or suffering or content intended to shock or disgust viewers or inciting others to commit violent acts.&nbsp; If you believe anyone is in imminent danger, you should reach out to your legal authorities to report the situation immediately.&nbsp;&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Inciting others to commit violent acts against individuals or a defined group of people; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Fights involving minors; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Footage, audio, or imagery involving road accidents, natural disasters, war aftermath, terrorist attack aftermath, street fights, physical attacks, sexual assaults, immolation, torture, corpses, protests or riots, robberies, medical procedures, or other such scenarios with the intent to shock or disgust viewers; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Physical violence or accidental deaths of people; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Dismembered, mutilated, charred, or burned human or animal remains; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Gory content&nbsp; in which an open wound or injury is the core focus; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Footage or imagery showing bodily fluids, such as blood or vomit, with the intent to shock or disgust viewers. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content where there is infliction of unnecessary suffering or harm deliberately causing an animal distress or animal cruelty; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content where animals are encouraged or coerced to fight by humans &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Dramatized or fictional footage of anything listed above where the viewer is not provided with enough context to determine that the footage is dramatized or fictional; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Footage of corpses with massive injuries, such as severed limbs; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Encouraging others to go to a particular place to commit violence, to perform violence at a particular time, or to target individuals or groups with violence; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Footage of violent crimes such as robberies or rape being committed&nbsp; that provide no education or explanation to viewers.; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Animal hunting. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; Exceptions: We might allow content that depict the above mentioned acts if they are a part of staged or professional fighting, traditional martial arts, or fighting in a fictional setting,&nbsp; which shall be the sole discretion of SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; VI. DANGEROUS INDIVIDUALS AND ORGANIZATIONS</p>
                    <p class="">&nbsp; &nbsp; We do not promote any content intended to praise, promote, or aid violent organizations such as criminal organization or terrorist organization or individuals belonging to such organizations are not allowed to use SKILLWINZ app for any purpose.&nbsp; We define dangerous individuals and organizations as those that commit crimes or cause other types of severe harm. The types of groups and crimes include, but are not limited to: hate groups, violent extremist organizations, homicide, human trafficking, organ trafficking, arms trafficking, drug trafficking, kidnapping, extortion, blackmailing, money laundering, fraud, cybercrime. We do not allow dangerous individuals or organizations to use our platform to promote terrorism, crime, or other types of behaviour that could cause harm.&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Content produced by violent criminal or terrorist organizations or dangerous individuals; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content praising or memorializing prominent terrorist or criminal figures in order to encourage others to carry out acts of violence; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content praising or justifying violent acts carried out by violent criminal or terrorist organizations or dangerous individuals; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content aimed at recruiting new members to violent criminal or terrorist organizations ; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content depicting hostages or posted with the intent to solicit, threaten, or intimidate on behalf of a violent criminal or terrorist organization or dangerous individuals; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that depicts the insignia, logos, or symbols, uniforms, gestures, portraits, or other objects meant to represent violent criminal or terrorist organizations; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that praises, glorifies, or supports dangerous individuals and/or organizations; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Raw and unmodified reuploads of content created by terrorist or criminal organizations; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Celebrating terrorist leaders or their crimes in songs or memorials; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Celebrating terrorist or criminal organizations or dangerous individuals in songs or memorials; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content directing users to sites that espouse terrorist ideology, are used to disseminate prohibited content, or are used for recruitment; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Video game content which has been developed or modified to glorify a violent event, its perpetrators, or support violent criminal or terrorist organizations or dangerous individuals; &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; Exceptions: We might allow content that depict the above mentioned acts if such content is posted for an educational, documentary, scientific purpose, please ensure to provide enough information in the video or audio itself so viewers understand the context, which shall be the sole discretion of SKILLWINZ. Graphic or controversial footage with sufficient context may be subject to warning screen.</p>
                    <p class="">&nbsp; &nbsp; VII. SUICIDE, SELF-HARM, AND DANGEROUS ACTS</p>
                    <p class="">&nbsp; &nbsp; We do not promote participation in activities that could lead to harm. We also do not permit users to encourage others to take part in dangerous activities. We do not allow content that promotes self-harm or suicide.&nbsp; However, users should not be afraid to speak openly about the topics of mental health or self-harm.&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Promoting or glorifying suicide, suicidal tendencies or content that might encourage participation in similar actions; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Providing instructions on how to self-harm or die by suicide; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Graphic images of self harm, self inflicted wounds; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Attempts of suicide or content featuring a person committing or intending to commit acts that are likely to lead to self-inflicted death; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Suicide challenges; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Inappropriate use of dangerous tools; &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; Exceptions: Content that provides support, resources, or coping mechanisms for those going through suicidal ideation</p>
                    <p class="">&nbsp; &nbsp; We as a community encourage users who are struggling with thoughts of self-harm or suicide, or contemplating suicide, to immediately contact legal authorities or a suicide prevention hotline. Being a responsible.company, SKILLWINZ may also report risky behaviour to relevant authorities, helplines &amp; third party experts and block your access.</p>
                    <p class="">&nbsp; &nbsp; VIII. HATE SPEECH, SLURS AND HATEFUL IDEOLOGIES</p>
                    <p class="">&nbsp; &nbsp; We do not tolerate content or any communication between users that attacks or incites violence against an individual or a group of individuals on the basis of certain attributes. We do not allow content any communication between users that includes hate speech, content promoting violence or hatred against individuals or groups based on any of the following attributes: age, caste, disability, ethnicity, gender identity and expression, nationality, race, immigration status, religion, sex/gender, sexual orientation, victims of a major violent event and their kin, veteran status, immigration status.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; Hate speech is content that directly or indirectly attack, threaten, incite violence against, or dehumanize an individual or a group of individuals on the basis of the attributes mentioned above.</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Encourage violence against individuals or groups based on any of the attributes noted above; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Incite hatred against individuals or groups based on any of the attributes noted above; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Actions/words that invokes non-consensual slurs which constitute as derogatory terms that are intended to disparage an ethnicity, race, or any other attributes listed above; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Actions/words that dehumanizes or incites violence or hatred against individuals or groups, based on the attributes listed above; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Action/words that promotes any hateful ideologies by talking positively about or displaying logos, symbols, flags, slogans, uniforms, salutes, gestures, portraits, illustrations, or names of individuals related to these ideologies; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Music or lyrics that promote hateful ideologies; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; IX. HARASSMENT AND BULLYING</p>
                    <p class="">&nbsp; &nbsp; We deeply understand the psychological distress that abusive content can have on individuals, and we do not tolerate abusive content or behaviour on our platform. Users should feel safe to express themselves responsibly without fear of being shamed, humiliated, bullied, or harassed. Content that threatens individuals is not allowed on SKILLWINZ app. We also do not allow content that targets an individual with prolonged or malicious insults based on intrinsic attributes, including their group status or physical traits.&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Content uploaded with the intent to shame, deceive or insult a minor;; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; violent threats, sexual harassment, disparaging statements regarding appearance, intellect, personality traits, and hygiene; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that threatens an individual with violence; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that wishes death, serious disease, physical, or other harm on an individual; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that incites violence or coordinated harassment; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that sexually harasses a user by disparaging their sexual activities or attempting to make unwanted sexual contact; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that praises violent tragedies and disparages their victims &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that uses the comment or react feature of SKILLWINZ app to create abusive content targeting other users; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Repeatedly showing pictures of someone and then making disparaging or hurtful statements; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Accounts dedicated entirely to focusing on maliciously insulting an identifiable individual; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Threatening someone’s physical safety;&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Posting an individual’s non public personal identifying information like a phone number, home address, or email to direct abusive attention or traffic toward them; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Directing malicious abuse to identifiable individuals through in-game voice chat or messages during a stream; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Stalking or attempting to blackmail users; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Zooming in on prolongedly focused emphasis on the breasts, buttocks or genital area of an identifiable individual for the purposes of degrading, objectifying, or sexualizing; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Video game content which has been developed or modified to promote violence or hatred against an individual with the attributes noted above. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; Exceptions: We may allow content that includes harassment and steps to cope with harassment if the primary purpose is educational such as harassment education and awareness, documentary, scientific, or artistic in nature. This is based on the sole discretion of SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; X. INTEGRITY AND AUTHENTICITY</p>
                    <p class="">&nbsp; &nbsp; We do not allow content that is intended to deceive or mislead any of our users including. activities such as spamming, impersonation, and disinformation campaigns:</p>
                    <p class="">&nbsp; &nbsp; 1. Spam</p>
                    <p class="">&nbsp; &nbsp; We don’t allow content where the main purpose is to directly or indirectly allude users to leave SKILLWINZ app for another website.&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Video Spam: Content that is excessively posted, repetitive, or untargeted and does one or more of the following, promises viewers they’ll see something but instead directs them off site, gets clicks, views, or traffic off SKILLWINZ app by promising viewers that they will make money fast, sends audiences to sites that spread malware, try to gather personal information, or other sites that have a negative impact and content with misleading show names which do not match the content; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Any direct monetary transfer between the users outside the SKILLWINZ app; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Any direct or indirect transfers between users for the purpose of gaining any unfair or undue advantage on the SKILLWINZ app via settlement with modes outside the SKILLWINZ app; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Any non-monetary fake transfer (including gifts) between users for the purpose of gaining any unfair or undue advantage on the SKILLWINZ app; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Using the title, thumbnails, description, or tags to trick users into believing the content is something it is not; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Manipulated Media: Content that has been technically manipulated or doctored in a way that misleads users (beyond clips taken out of context) and may pose a serious risk; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that advances false claims related to the technical eligibility requirements for current political candidates and sitting elected government officials to serve in office; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Hurtful and unsolicited comments;&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Comments with links to counterfeit stores; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Operate multiple SKILLWINZ accounts under false or fraudulent pretences, including coordinated attempts to manufacture inauthentic activity, distribute commercial spam, or otherwise coordinate a scaled violation of SKILLWINZ policies. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; 2. Impersonation</p>
                    <p class="">&nbsp; &nbsp; We do not allow users to impersonate other individuals or organizations in order to deceive the public. When we confirm reports of impersonation, we remove the violating accounts.&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; As another person or organization by using someone else’s name, biographical details, or profile picture in a misleading manner; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Using someone else’s real name, user name, image, brand, logo, or other personal information;&nbsp; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Setting up a user account using the name and image of a person, and then pretending that person is posting content or comments. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; Exception – We do allow exceptions for parody, commentary, or fan accounts, as long as the account does not mislead others with regard to its identity or purpose on SKILLWINZ app.&nbsp; Any such exception shall be at the sole discretion of SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; 3. Misleading information</p>
                    <p class="">&nbsp; &nbsp; We do not permit misinformation that could cause harm to our community or the larger public. While we encourage our users to have respectful conversations about the subjects that matter to them, we remove misinformation that could cause harm to an individual’s health or wider public safety. We also remove content distributed by disinformation campaigns.&nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Misinformation meant to incite fear, hate, or prejudice; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Misinformation that may cause harm to an individual’s health, such as misleading information about medical treatments; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Hoaxes, phishing attempts, or manipulated content meant to cause harm; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that misleads community members about elections or other civic processes. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; XI. ILLEGAL ACTIVITIES AND REGULATED GOODS</p>
                    <p class="">&nbsp; &nbsp; We prohibit the trade, sale, promotion, and use of certain regulated goods, as well as the depiction or promotion of criminal activities. Some content may be removed if it relates to activities or goods that are illegal or regulated in the majority of the region or world, even if the activities or goods in question are legal in the jurisdiction of posting. Criminal activities include acts punishable by law, including theft, assault, human exploitation, and other harmful behaviour.</p>
                    <p class="">&nbsp; &nbsp; &nbsp;</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Promotion of criminal activities; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that promotes acts of physical harm, such as assaulting or kidnapping; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that risks the safety of others; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that promotes human exploitation or wildlife trafficking; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that offers the purchase, sale, or trade of unlawfully acquired goods; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that provides instructions on how to conduct criminal activities; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Depiction, trade, or promotion of firearms, ammunition, firearm accessories, or explosive weapons or instructions on process to manufacture those weapons; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that offers, sells, trades, or solicits firearms, accessories, ammunition, explosive weapons, or instructions on how to manufacture them; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Use or trade of drugs or other controlled substances; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that displays drugs, drug consumption, or encourages others to make, use, or trade drugs or other controlled substances; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that sells, offers, trades, or solicits, provides information on how to buy drugs or other controlled substances; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that violates any law for the time being in force &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; Exception – We may provide&nbsp; exceptions to content that displays firearms or other types of weapons in the following scenarios: in a fictional setting, as part of a museum’s collection, carried by a police officer, in a military parade, or used in a safe and controlled environment such as a shooting range.&nbsp; We may allow exceptions for content that provides value to the public, such as educational, scientific, artistic, and newsworthy content. Any such exception shall be at the sole discretion of SKILLWINZ.</p>
                    <p class="">&nbsp; &nbsp; XII. FRAUDS AND SCAMS</p>
                    <p class="">&nbsp; &nbsp; We do not permit anyone to exploit our platform to harm others, which includes schemes to defraud individuals or steal assets.</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Content that promotes phishing; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that promotes any schemes; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content that promotes fixed betting, get-rich-quick schemes, or any other types of scams; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Content relating or encouraging money laundering or gambling &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Not adhering to commitments or fulfilling agreements made by or entered into by the users, including non payment of rewards to users as promised during using the SKILLWINZ app while live streaming content. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; XIII. INFRINGEMENT OF OTHERS’ PRIVACY</p>
                    <p class="">&nbsp; &nbsp; Revealing or threatening to reveal personally identifiable information can cause serious emotional distress and lead to real-world harm.&nbsp; If someone posts your personal information without your consent, please feel free to report a privacy breach complaint by providing your uniquely identifiable information such as image or voice, full name, financial information, contact information, other personally identifiable information.</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Content that reveals or threatens to reveal personally identifiable information, including but not limited to residential address, private email address, private phone number, bank statement, social security number, or passport number; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Threats to reveal sexual imagery or non-consensual intimate imagery; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Use personal information gathered on app to have personal conversation with user on another app or any communication medium. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; XIV. THREATS TO PLATFORM SECURITY</p>
                    <p class="">&nbsp; &nbsp; In addition to the content and behaviour outlined above, our policies prohibit activities that undermine the SKILLWINZ service:</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Do not hack the SKILLWINZ app, or associated networks, or bypass its measures to restrict users’ access; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Do not distribute files that contain viruses, Trojan horses, worms, logic bombs, or other materials that are malicious or harmful; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Do not use automated scripts to collect information from SKILLWINZ. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Do not post any content or indulge in communications with users to provide tips and encouraging ways to hack the SKILLWINZ app or engage in any other activities that threaten or compromise the platform security of SKILLWINZ app. &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; XV. ENCOURAGING TERMS OF SERVICE VIOLATIONS</p>
                    <p class="">&nbsp; &nbsp; If you post or stream any content that encourages other users to violate our Terms of Service, the content may be removed, your account may be penalized, and in some cases your account may be terminated.</p>
                    <p class="">&nbsp; &nbsp; XVI. LINKS IN YOUR CONTENT</p>
                    <p class="">&nbsp; &nbsp; Links that send users to websites featuring content that violates our Community Guidelines are not allowed on SKILLWINZ. Don’t post links in your content on SKILLWINZ if they fit any of the descriptions noted below.</p>
                    <ul class="">
                        <li class="">&nbsp; &nbsp; Links to pornography; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Links to websites or apps that install malware; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Links to websites or apps phishing for a user’s login credentials, financial information, etc. &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Links to websites that seek to raise funds or recruit for terrorist organizations; &nbsp; &nbsp;</li>
                        <li class="">&nbsp; &nbsp; Links to sites containing Child Sexual Abuse Imagery (CSAI). &nbsp; &nbsp;</li>
                    </ul>
                    <p class="">&nbsp; &nbsp; ACTIONS THAT WILL BE TAKEN IF YOU POST ANY CONTENT THAT VIOLATES THE COMMUNITY GUIDELINES</p>
                    <p class="">&nbsp; &nbsp; If you see something that you think may violate our Community Guidelines, please help us by reporting the same on our reporting option available on the users’ profile. We will review these reports internally and will work as quickly and as efficiently as possible to rsemove content that does not meet our Community Guidelines, or take actions appropriate for such content after review. Please help us by providing as much information as possible, such as links, usernames, and descriptions of the content, so we can find and review it effectively.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; We take genuine intellectual property infringement complaints very seriously. If you come across any content that you feel may be infringing any intellectual property, please report to us at the earliest and we shall review such complaint as quickly as possible.&nbsp; While doing so, we request you to provide us as much information as possible such as the reason why you feel the content infringes any intellectual property.&nbsp;</p>
                    <p class="">&nbsp; &nbsp; We may work with law enforcement whenever necessary, including when we believe that there’s risk of physical harm or threat to public safety.</p>
                    <p class="">&nbsp; &nbsp; We would like to thank you for helping us as we strive to be one of the best and compliant communities.</p>
                    <p   class="">&nbsp; &nbsp; The SKILLWINZ Team.</p>

                    <p>Skillwinz is a part of  Fantasy Score 11 Pvt. Ltd.</p>
                </div>
                </div>
            </section>
            {/* <!-- Copyright Area Start --> */}
            <div className="copyright-area  ptb-70 pt-0 pb-4">
                <div className="container">
                    {/* <!-- Contact address left --> */}
                    <div className="conct-border row align-items-center">
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" /> </a>
                        </div>
                        <div className="col-md-7">
                            <div className="single-address">
                                <a href="/fairplay"  >Skillwinz Fairplay</a>
                                <a href="/termsconditions">Terms & Conditions</a>
                                <a href="/privacypolicy">Privacy Policy</a>
                                <a href="/legality"> Legality </a>
                                <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                <a href="/anti-money-laundering-policy">Anti-Money Laundering  </a>
                                <a href="/refund-policy"> Refund Policy  </a>
                                <a href="/contact"> Contact us    </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Contact address left --> */}
                    {/* <!-- Copyright right --> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area text-center">
                                {/* <!-- Copyright social --> */}
                                {/* <!-- <div className="contact-social text-center ptb-50 pb-35">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-vimeo"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-google-plus"></i></a>
                                        </li>
                                    </ul>
                                </div> --> */}
                                {/* <!-- Copyright social --> */}
                                <div className="copyright-text">
                                    <p>Copyright &copy;  <a href="#"> Skill Winz </a> All Rights Reserved.  Design by <a href="https://appinop.com" target="_blank"  >Appinop Technologies</a><br/>Skillwinz is a part of  Fantasy Score 11 Pvt. Ltd.</p>
                                
                                    <p>
                                        <b>DISCLAIMER</b><br/>
                                        This game may be habit-forming or financially risky. Play responsibly. Skillwinz is the biggest social gaming app in India by number of games, languages and exciting formats on the platform. Skillwinz is only available for people who are above 18 years of age. Skillwinz is available only in those Indian states where skill gaming is allowed by regulations.Fantasy Score 11 Pvt. Ltd. is the sole owner of and reserves the right to “Skillwinz” trademark, logos, assets, content, information, etc. used in the website except the third party content. Fantasy Score 11 Pvt. Ltd. does not acknowledge the accuracy or reliability of third party content.
                                    </p>
                                </div>
                                {/* <!-- Copyright text --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Copyright right --> */}
                </div>
            </div >
            {/* <!-- Copyright Area End --> */}
        </>
    )
}

export default TermsConditions
